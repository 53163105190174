<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
      class="d-print-none"
    >
    <router-link to="/">
        <v-img
          v-if="iroda != 'gyakorlat'"
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="./assets/bill_3.png"
          transition="scale-transition"
          width="50"
        />
        <v-img
          v-if="iroda == 'gyakorlat'"
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="./assets/drive.png"
          transition="scale-transition"
          width="50"
        /> 
      </router-link>

<!--   <div class="hidden-md-and-down">
        <v-btn class="subtitle-2" text to="/szamlak">Számlák<v-icon right>all_inbox</v-icon></v-btn>
        <v-btn class="subtitle-2" v-if="iroda != 'gyakorlat'" text to="/elszam" @click="refreshElszam()">Elszámolás<v-icon right>poll</v-icon></v-btn>
        <v-btn class="subtitle-2" v-if="iroda != 'gyakorlat'" text to="/leltar" @click="refreshLeltar()">Leltár<v-icon right>library_add_check</v-icon></v-btn>
        <v-btn class="subtitle-2" v-if="fbuser == 'admin@szamlazo.hu' || fbuser == 'dgabor9@gmail.com' || fbuser == 'suli@csigajogsi.hu'" text to="/beiratkozok">Beiratkozók<v-icon right>account_circle</v-icon></v-btn>
        <v-btn class="subtitle-2" v-if="iroda != 'gyakorlat'" text to="/levet">Levét<v-icon right>payments</v-icon></v-btn>
        <v-btn class="subtitle-2" v-if="iroda != 'gyakorlat'" text to="/bank">Bank<v-icon right>account_balance</v-icon></v-btn>
        <v-btn class="subtitle-2" v-if="fbuser == 'admin@szamlazo.hu' || fbuser == 'dgabor9@gmail.com' || fbuser == 'suli@csigajogsi.hu'" text to="/gyakorlat">Gyakorlat<v-icon right>directions_car</v-icon></v-btn>
        <v-btn icon to="/login"><v-icon>power_settings_new</v-icon></v-btn>
      </div> -->
      <div class="title hidden-sm-and-down">{{username}}</div>
      <v-spacer></v-spacer>
      <v-col class="mt-8" cols="8" md="4" lg="4">
        <v-select
          v-model="school_id"
          :items="school_select"
          label="Cég választás"
          @change="clearParams()"
          outlined
          dense
          :disabled="mod_invoice_id"
        ></v-select>
      </v-col>
      <v-col class="mt-8 hidden-sm-and-down" cols="3" md="3" lg="3" v-if="fbuser == 'admin@szamlazo.hu' || fbuser == 'dgabor9@gmail.com' || fbuser == 'suli@csigajogsi.hu'">
        <v-select
          v-model="select_user"
          :items="all_user"
          item-text="username"
          return-object
          label="Iroda választás"
          outlined
          dense
        ></v-select>
      </v-col>
      <v-spacer></v-spacer>

      <template v-slot:extension>
      <div class="hidden-sm-and-down">
        <v-btn class="subtitle-2" text to="/szamlak">Számlák<v-icon right>all_inbox</v-icon></v-btn>
        <v-btn class="subtitle-2" v-if="iroda != 'gyakorlat'" text to="/elszam" @click="refreshElszam()">Elszámolás<v-icon right>poll</v-icon></v-btn>
        <v-btn class="subtitle-2" v-if="iroda != 'gyakorlat'" text to="/leltar" @click="refreshLeltar()">Leltár<v-icon right>library_add_check</v-icon></v-btn>
        <v-btn class="subtitle-2" v-if="fbuser == 'admin@szamlazo.hu' || fbuser == 'dgabor9@gmail.com' || fbuser == 'suli@csigajogsi.hu'" text to="/beiratkozok">Beiratkozók<v-icon right>account_circle</v-icon></v-btn>
        <v-btn class="subtitle-2" v-if="iroda != 'gyakorlat'" text to="/levet">Levét<v-icon right>payments</v-icon></v-btn>
        <v-btn class="subtitle-2" v-if="iroda != 'gyakorlat'" text to="/bank">Bank<v-icon right>account_balance</v-icon></v-btn>
        <v-btn class="subtitle-2" text to="/gyakorlat">Gyakorlat<v-icon right>directions_car</v-icon></v-btn>
        <!-- <v-btn v-if="admin" to="/settings" icon><v-icon>settings</v-icon></v-btn> -->
        <!-- <v-btn icon to="/login"><v-icon>power_settings_new</v-icon></v-btn> -->
      </div>
      <div class="title hidden-md-and-up">
        {{$route.name}}
      </div>
      <v-spacer></v-spacer>
      <div class="hidden-sm-and-down">
        <v-btn icon to="/login"><v-icon>power_settings_new</v-icon></v-btn>
      </div>
      </template>

      <v-spacer></v-spacer>
      <div class="hidden-md-and-up">
      <v-menu :close-on-content-click="false" ref="mobileMenu">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list rounded>
          <v-list-item-group color="indigo">
          <v-container class="pb-0">
          <p class="title">{{username}}</p>
            <v-select
              class="hidden-md-and-up"
              v-model="select_user"
              :items="all_user"
              item-text="username"
              return-object
              label="Iroda választás"
              outlined
              dense
              v-if="fbuser == 'admin@szamlazo.hu' || fbuser == 'dgabor9@gmail.com' || fbuser == 'suli@csigajogsi.hu'"
            ></v-select>
          </v-container>
          <v-list-item to="/szamlak" @click="$refs.mobileMenu.isActive = false">
            <v-list-item-title>Számlák<v-icon right>all_inbox</v-icon></v-list-item-title>
          </v-list-item>
          <v-list-item to="/elszam" v-if="iroda != 'gyakorlat'" @click="$refs.mobileMenu.isActive = false">
            <v-list-item-title @click="refreshElszam()">Napi elszámolás<v-icon right>poll</v-icon></v-list-item-title>
          </v-list-item>
          <v-list-item to="/leltar" v-if="iroda != 'gyakorlat'" @click="$refs.mobileMenu.isActive = false">
            <v-list-item-title @click="refreshLeltar()">Leltár<v-icon right>library_add_check</v-icon></v-list-item-title>
          </v-list-item>
          <v-list-item to="/beiratkozok" v-if="fbuser == 'admin@szamlazo.hu' || fbuser == 'dgabor9@gmail.com' || fbuser == 'suli@csigajogsi.hu'" @click="$refs.mobileMenu.isActive = false">
            <v-list-item-title >Beiratkozók<v-icon right>account_circle</v-icon></v-list-item-title>
          </v-list-item>
          <v-list-item to="/levet" v-if="iroda != 'gyakorlat'" @click="$refs.mobileMenu.isActive = false">
            <v-list-item-title >Levét<v-icon right>payments</v-icon></v-list-item-title>
          </v-list-item>
          <v-list-item to="/bank" v-if="iroda != 'gyakorlat'" @click="$refs.mobileMenu.isActive = false">
            <v-list-item-title >Bank<v-icon right>account_balance</v-icon></v-list-item-title>
          </v-list-item>
          <v-list-item to="/gyakorlat" @click="$refs.mobileMenu.isActive = false">
            <v-list-item-title >Gyakorlat<v-icon right>directions_car</v-icon></v-list-item-title>
          </v-list-item>
          <v-list-item to="/settings" v-if="admin" @click="$refs.mobileMenu.isActive = false">
            <v-list-item-title>Beállítás<v-icon right>settings</v-icon></v-list-item-title>
          </v-list-item>
          <v-list-item to="/login" @click="$refs.mobileMenu.isActive = false">
            <v-list-item-title>Belépés<v-icon right>power_settings_new</v-icon></v-list-item-title>
          </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      </div>

      <v-progress-linear :active="preloader" :indeterminate="preloader" absolute bottom color="red"></v-progress-linear>
    </v-app-bar>

    <div class="mb-12"></div>
    <div class="mb-12"></div>
    <div class="mb-8"></div>
    
    <!--
    <v-container>
    <v-col class="hidden-md-and-up" cols="12" md="2" v-if="fbuser == 'admin@szamlazo.hu' || fbuser == 'dgabor9@gmail.com' || fbuser == 'suli@csigajogsi.hu'">
      <v-select
        v-model="select_user"
        :items="all_user"
        item-text="username"
        return-object
        label="Iroda választás"
        outlined
        dense
      ></v-select>
    </v-col>
    </v-container>
   -->
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          v-show="($route.path == '/szamlak' || $route.path == '/') && fbuser != 'suli@csigajogsi.hu'"
          @click="new_invoice()"
          v-on="on"
          fixed
          style="bottom: 70px;"
          dark
          fab
          bottom
          right
          color="white"
          to="/"
          class="d-print-none"
        >
        <img src="https://www.gstatic.com/images/icons/material/colored_icons/1x/create_32dp.png">
        </v-btn>
      </template>
      <span>Új számla létrehozása</span>
    </v-tooltip>

    <v-snackbar
      
      bottom
      v-model="dev_snackbar"
      color="red lighten-1"
    >
    TESZT ÜZEMMÓD!
    <v-btn icon color="white" @click="dev_snackbar = false">
          <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>

    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </v-app>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import firebase from 'firebase/app'
import 'firebase/auth';
import { EventBus } from './event-bus';
import router from './router'

export default {
  name: 'App',

  components: {

  },

  data () {
    return {
      param: '',
      response: '',
      school_select: [
        { text: 'Csigavér 01 Autósiskola Kft', value: 1},
          { text: 'Csigavér 02 Kft.', value: 2},
      ],
      all_user: [],
      select_user: [],
      dev_snackbar: false
    }
  },
  methods: {
    userState: function () {
      var user = firebase.auth().currentUser;
      if (user) {
        this.fbuser = user.email;
      } else {
        console.log("No user is signed in.");
      }
    },
    getUser: async function() {
      await this.gettoken()
      this.$http.post('users', {
        token: this.token,
        fbuser: this.fbuser
      }).then((response) => {
        this.all_user = response.data[0]
        this.username = response.data['username']
        this.user_place_id = response.data['user_place_id']
        this.iroda = response.data['iroda']
        this.admin = response.data['admin']
        this.select_user = {'username': response.data['username'], 'user_place_id': response.data['user_place_id'], 'iroda': response.data['iroda']}
        if (this.fbuser == 'suli@csigajogsi.hu') {
          this.$router.push({ path: '/beiratkozok' });
        }
      }, (error) => {
        this.error_text = error.data
      })
    },
    new_invoice() {
      EventBus.$emit('newInvoice')
    },
    refreshElszam() {
      EventBus.$emit('elszamRefreshAll')
    },
    refreshLeltar() {
      EventBus.$emit('leltarRefresh')
    },
    getSettings: async function() {
      await this.gettoken()
      this.$http.post('services/settings_load', {
        token: this.token,
      }).then((response) => {
        this.dev_settings = response.data
      }, (error) => {
        this.error_text = error.data
      })
    },
    clearParams() {

    }
  },
  beforeMount() {
    if (this.$route.query.invoice_number) {
      this.preselected_invoice = this.$route.query.invoice_number
      this.school_id = parseInt(this.$route.query.school)
      this.preselected_school_id = parseInt(this.$route.query.school)
    } else if (this.$route.query.contractId) {
      this.contractId = this.$route.query.contractId
      this.customerId = this.$route.query.customerId
    } else {
      this.preselected_school_id = 1
      this.contractId = null
      this.customerId = null
    }
  },
  mounted() {
    this.userState()
    this.getUser()
    this.getSettings()
  },
  watch: {
    school_id() {
      this.invoice_tab = 0
    },
    fbuser(value) {
      this.getUser()
      if (value == 'gyakorlat@szamlazo.hu') {
        this.$vuetify.theme.themes.light.primary = "#EC407A"
      }
    },
    select_user(value) {
      this.username = value.username
      this.user_place_id = value.user_place_id
      this.iroda = value.iroda
    },
    dev_settings(value) {
      if (value == 'dev') {
        this.dev_snackbar = true
      } else if (value == 'prod') {
        this.dev_snackbar = false
      }
    },
  },
  computed: {
  ...mapFields([
    'ids.customerId',
    'ids.contractId',
    'functions.invoice_tab',
    'ids.school_id',
    'ids.preselected_school_id',
    'functions.invoice_preview',
    'ids.invoice_id',
    'ids.mod_invoice_id',
    'ids.preselected_invoice',
    'functions.preloader',
    'ids.fbuser',
    'ids.username',
    'ids.user_place_id',
    'ids.iroda',
    'ids.admin',
    'functions.refresh_store',
    'functions.dev_settings',
  ]),
},
};
</script>

<style lang="css">
</style>
