import Vue from 'vue'
import Router from 'vue-router'
import Login from './Login.vue'
import Clients from './components/Clients.vue'
import Invoice from './components/Invoice.vue'
import InvoiceList from './components/InvoiceList.vue'
import Elszamolas from './components/Elszamolas.vue'
import Leltar from './components/Leltar.vue'
import Settings from './components/Settings.vue'
import Beiratkozok from './components/Beiratkozok.vue'
import Bank from './components/Bank.vue'
import Levet from './components/Levet.vue'
import Gyakorlat from './components/Gyakorlat.vue'
import firebase from 'firebase/app'
import 'firebase/auth';
import store from './store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  //base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: "Számla létrehozása",
      component: Invoice
    },
    {
      path: '/szamlak',
      name: "Számlák",
      component: InvoiceList
    },
    {
      path: '/ugyfelek',
      name: "Ügyfelek",
      component: Clients
    },
    {
      path: '/login',
      name: "Bejelentkezés",
      component: Login
    },
    {
      path: '/elszam',
      name: "Elszámolás",
      component: Elszamolas
    },
    {
      path: '/leltar',
      name: "Leltár",
      component: Leltar
    },
    {
      path: '/levet',
      name: "Levét",
      component: Levet
    },
    {
      path: '/beiratkozok',
      name: "Beiratkozók",
      component: Beiratkozok
    },
    {
      path: '/bank',
      name: "Bank",
      component: Bank
    },
    {
      path: '/gyakorlat',
      name: "Gyakorlat",
      component: Gyakorlat
    },
    {
      path: '/settings',
      name: "Beállítások",
      component: Settings,
      beforeEnter(to, from, next) {
        if (store.state.ids.admin == 1) {
          next()
        } else {
          next({
            name: "home" // back to safety route //
          });
        }
      }
    },
  ]
})

router.beforeEach((to, from, next) => {
  var user = firebase.auth().currentUser
  if (!user && to.path !== '/login') next({ path: 'login', query: to.query })
  else next()
})

export default router
