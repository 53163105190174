<template lang="html">
  <div class="component-leltar">
    <v-container>
      <v-card>
        <v-card-title>
          Raktárkészlet ({{username}})
          <v-spacer></v-spacer>
        </v-card-title>
      <v-data-table
         :headers="stock_headers"
         :items="stock"
         :items-per-page="15"
         class="d-print-table"
         :loading="table_loader"
         loading-text="Betöltés..."
       >
      </v-data-table>
      </v-card>
      <br>
      <v-card>
        <v-card-title>
          Raktári bevétek, kiadások
          <v-spacer></v-spacer>
        </v-card-title>
      <v-data-table
         :headers="stock_changes_headers"
         :items="stock_changes"
         :items-per-page="15"
         class="d-print-table"
         :loading="table_loader"
         loading-text="Betöltés..."
       >
      </v-data-table>
      </v-card>
      <br>
      <v-card v-if="fbuser != 'suli@csigajogsi.hu'">
        <v-card-title>
          Bevételezés
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
        <v-container>
        <v-form
          ref="stock_form"
          v-model="stock_valid"
          lazy-validation
        >
        <v-row>
          <v-col cols="12" md="3">
            <span>Termék</span>
            <v-autocomplete
              v-model="selected_product"
              :items="products"
              item-text="[prod_name]"
              return-object
              label="Termék választása"
              outlined
              flat
              solo
              :rules="[rules.required]"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="3">
            <span>Mennyiség</span>
            <v-text-field
              v-model="amount"
              outlined
              flat
              solo
              type="number"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <span>Dátum</span>
                <v-text-field
                  v-model="date"
                  append-icon="event"
                  readonly
                  outlined
                  flat
                  solo
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="date" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">Mégsem</v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="3">
            <v-btn
              rounded
              color="primary"
              class="mt-8"
              @click="stock_validate()"
            >
              Rögzít
              <v-icon right dark>save</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        </v-form>
        </v-container>
        </v-card-text>
      </v-card>
      <br>
      <v-card v-if="fbuser != 'suli@csigajogsi.hu'">
        <v-card-title>
          Raktári kiadás
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
        <v-container>
        <v-form
          ref="expense_form"
          v-model="expense_valid"
          lazy-validation
        >
        <v-row>
          <v-col cols="12" md="3">
            <span>Termék</span>
            <v-autocomplete
              v-model="selected_product_expense"
              :items="products"
              item-text="[prod_name]"
              return-object
              label="Termék választása"
              outlined
              flat
              solo
              :rules="[rules.required]"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="3">
            <span>Mennyiség</span>
            <v-text-field
              v-model="amount_expense"
              outlined
              flat
              solo
              type="number"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <span>Iroda</span>
            <v-select
              v-model="office"
              :items="office_names"
              return-object
              label="Bevételező iroda"
              outlined
              flat
              solo
              :rules="[rules.required]"
            ></v-select>
          </v-col>
          <v-col cols="12" md="3">
            <span>Megjegyzés</span>
            <v-text-field
              v-model="comment"
              outlined
              flat
              solo
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-menu
              ref="menu_expense"
              v-model="menu_expense"
              :close-on-content-click="false"
              :return-value.sync="date_expense"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <span>Dátum</span>
                <v-text-field
                  v-model="date_expense"
                  append-icon="event"
                  readonly
                  outlined
                  flat
                  solo
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="date_expense" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu_expense = false">Mégsem</v-btn>
                <v-btn text color="primary" @click="$refs.menu_expense.save(date_expense)">OK</v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="3">
            <v-btn
              rounded
              color="primary"
              class="mt-8"
              @click="expense_validate()"
            >
              Rögzít
              <v-icon right dark>save</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        </v-form>
        </v-container>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields';
import { EventBus } from '../event-bus';

  export default {
    data () {
      return {
        stock: [],
        stock_changes: [],
        table_loader: false,
        products: [],
        selected_product: '',
        selected_product_expense: '',
        amount: '',
        amount_expense: '',
        date: new Date().toISOString().substr(0, 10),
        date_expense: new Date().toISOString().substr(0, 10),
        menu: false,
        menu_expense: false,
        office: '',
        comment: '',
        stock_headers: [
          { text: 'Termék', value: 'product' },
          { text: 'Darabszám', value: 'amount' },
        ],
        stock_changes_headers: [
          { text: 'Termék', value: 'product' },
          { text: 'Darabszám', value: 'amount' },
          { text: 'Tárgy', value: 'szamla' },
          { text: 'Dátum', value: 'szamla_date' },
        ],
        office_names: [
          { text: 'Keleti', value: 'keleti' },
          { text: 'Sz. István', value: 'sztistvan' },
          { text: 'Fejér Lipót', value: 'kelenfold' },
          { text: 'Haller', value: 'haller' },
          { text: 'Helsinki', value: 'pesterzsebet' },
          { text: 'Üllői út', value: 'ulloi' },
        ],
        stock_valid: true,
        expense_valid: true,
        rules: {
            required: value => !!value || 'Kötelező mező!',
        },
      }
    },
    methods: {
      getStock: async function() {
        this.stock = []
        this.table_loader = true
        await this.gettoken()
        this.$http.post('services/get_stock', {
          token: this.token,
          iroda: this.iroda
        }).then((response) => {
          this.table_loader = false
          this.stock = response.data
        }, (error) => {
          this.error_text = error.data
        })
      },
      insertStockChanges: async function() {
        await this.gettoken()
        this.$http.post('services/stock_sql', {
          token: this.token,
          iroda: this.iroda
        }).then(() => {
          this.getStock()
          this.getStockChanges()
        }, (error) => {
          this.error_text = error.data
        })
      },
      getStockChanges: async function() {
        this.stock_changes = []
        this.table_loader = true
        await this.gettoken()
        this.$http.post('services/stock_changes', {
          token: this.token,
          iroda: this.iroda
        }).then((response) => {
          this.table_loader = false
          this.stock_changes = response.data
        }, (error) => {
          this.error_text = error.data
        })
      },
      getProducts: async function() {
        await this.gettoken()
        this.$http.post('services/stock_list', {
          token: this.token
        }).then((response) => {
          this.products = response.data
        }, (error) => {
          this.error_text = error.data
        })
      },
      insertStock: async function() {
        if (confirm("Biztosan menteni szeretné a tételt?")) {
        await this.gettoken()
        this.$http.post('services/insert_stock', {
          token: this.token,
          iroda: this.iroda,
          product_id: this.selected_product.prod_id,
          product_name: this.selected_product.prod_name,
          amount: this.amount,
          date: this.date,
        }).then(() => {
          this.selected_product = ''
          this.amount = ''
          this.stock_resetValidation()
          this.getStock()
          this.getStockChanges()
        }, (error) => {
          this.error_text = error.data
        })
       }
      },
      expenses: async function() {
        if (confirm("Biztosan menteni szeretné a tételt?")) {
        await this.gettoken()
        this.$http.post('services/expense_stock', {
          token: this.token,
          iroda: this.iroda,
          iroda_nev: this.username,
          product_id: this.selected_product_expense.prod_id,
          product_name: this.selected_product_expense.prod_name,
          amount: this.amount_expense,
          date: this.date_expense,
          comment: this.comment,
          office: this.office.value,
          office_name: this.office.text,
        }).then(() => {
          this.selected_product_expense = ''
          this.amount_expense = ''
          this.date_expense = ''
          this.comment = ''
          this.office = ''
          this.expense_resetValidation()
          this.getStock()
          this.getStockChanges()
        }, (error) => {
          this.error_text = error.data
        })
       }
      },
      stock_validate () {
        if (this.$refs.stock_form.validate()) {
          this.insertStock()
        }
      },
      stock_reset () {
        this.$refs.stock_form.reset()
      },
      stock_resetValidation () {
        this.$refs.stock_form.resetValidation()
      },
      expense_validate () {
        if (this.$refs.expense_form.validate()) {
          this.expenses()
        }
      },
      expense_reset () {
        this.$refs.expense_form.reset()
      },
      expense_resetValidation () {
        this.$refs.expense_form.resetValidation()
      },
      refresh() {
        this.insertStockChanges()
      }
    },
    mounted() {
      this.getProducts()
      this.refresh()
    },
    watch: {
      username() {
        this.refresh()
      },
    },
    created() {
      EventBus.$on('leltarRefresh', () => {
            this.refresh();
      })
    },
    computed: {
    ...mapFields([
      'ids.fbuser',
      'ids.username',
      'ids.user_place_id',
      'ids.iroda',
      'functions.refresh_store',
    ]),
    },
  }
</script>

<style lang="css">
</style>
