<template lang="html">
    <v-container>
      <v-row>
        <v-col cols="12" md="4">
          <v-radio-group v-model="dev_settings" row>
            <v-radio color="red" label="Éles rendszer" value="prod"></v-radio>
            <v-radio color="primary" label="Teszt rendszer" value="dev"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="5" md="5">
          <v-btn
            rounded
            dark
            color="red"
            class="mt-2"
            @click="saveSettings(), sandboxSetup()"
          >
            Rögzít
            <v-icon right dark>save</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
      <v-col cols="12" md="4">
        <v-autocomplete
          v-model="product"
          :items="product_list"
          label="Termékek"
          item-text="[attributes][comment]"
          item-value="[id]"
          outlined
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="4">
        <v-autocomplete
          v-model="nkh_category"
          :items="categories"
          label="NKH kategória"
          item-text="contract_name"
          item-value="nkh_course_type_id"
          outlined
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field
          v-model="product_price"
          label="Díjbekérő összeg"
          outlined
          type="number"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field
          v-model="elearning_price"
          label="E-learning összeg"
          outlined
          type="number"
        ></v-text-field>
      </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="from_date"
            label="Érvényesség kezdete"
            outlined
            v-mask="'####-##-##'"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="to_date"
            label="Érvényesség vége"
            outlined
            v-mask="'####-##-##'"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
      <v-col>
        <v-checkbox
          v-model="oktatas"
          label="Oktatás"
        ></v-checkbox>
      </v-col>
      <v-col>
        <v-checkbox
          v-model="gyakorlat"
          label="Gyakorlat"
        ></v-checkbox>
      </v-col>
      <v-col>
        <v-checkbox
          v-model="book"
          label="Tankönyv"
        ></v-checkbox>
      </v-col>
      <v-col>
        <v-checkbox
          v-model="biz_alap"
          label="Bizonylat alapján"
        ></v-checkbox>
      </v-col>
      <v-col>
        <v-checkbox
          v-model="vizsgadij"
          label="Vizsgadíj"
        ></v-checkbox>
      </v-col>
      <v-col>
        <v-checkbox
          v-model="prof_okt"
          label="Díjbekérő oktatás"
        ></v-checkbox>
      </v-col>
      <v-col>
        <v-checkbox
          v-model="stock"
          label="Leltár"
        ></v-checkbox>
      </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3">
          <v-btn
            rounded
            color="primary"
            class="mt-2"
            @click="newProduct()"
          >
            Új
            <v-icon right dark>add_circle_outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" md="3">
          <v-btn
            rounded
            color="primary"
            class="mt-2"
            @click="savedProductDetails()"
          >
            Rögzít
            <v-icon right dark>save</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" md="3">
          <v-btn
            rounded
            color="primary"
            class="mt-2"
            @click="saveAsProductDetails()"
          >
            Mentés másként
            <v-icon right dark>save</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" md="3">
          <v-btn
            rounded
            color="red"
            dark
            class="mt-2"
            @click="delProductOccur()"
          >
            Töröl
            <v-icon right dark>delete</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <br>
        <v-data-table
          :headers="headers"
          :items="saved_products"
          :items-per-page="15"
          class="elevation-1"
          @click:row="getSelectedProduct($event.id)"
        >
          <template v-slot:item.oktatas="props">
            <v-checkbox v-model="props.item.oktatas" disabled></v-checkbox>
          </template>
          <template v-slot:item.gyakorlat="props">
            <v-checkbox v-model="props.item.gyakorlat" disabled></v-checkbox>
          </template>
          <template v-slot:item.tankonyv="props">
            <v-checkbox v-model="props.item.tankonyv" disabled></v-checkbox>
          </template>
          <template v-slot:item.biz_alapjan="props">
            <v-checkbox v-model="props.item.biz_alapjan" disabled></v-checkbox>
          </template>
          <template v-slot:item.vizsgadij="props">
            <v-checkbox v-model="props.item.vizsgadij" disabled></v-checkbox>
          </template>
          <template v-slot:item.prof_oktatas="props">
            <v-checkbox v-model="props.item.prof_oktatas" disabled></v-checkbox>
          </template>
          <template v-slot:item.stock="props">
            <v-checkbox v-model="props.item.stock" disabled></v-checkbox>
          </template>
        </v-data-table>
    </v-container>
</template>

<script>
import {mapFields} from 'vuex-map-fields';
import { EventBus } from '../event-bus';

  export default {
    data () {
      return {
        product_list: [],
        saved_products: [],
        categories: [],
        nkh_category: '',
        product: '',
        product_price: '',
        elearning_price: '',
        stock: '',
        oktatas: '',
        gyakorlat: '',
        book: '',
        biz_alap: '',
        vizsgadij: '',
        prof_okt: '',
        headers: [
           { text: 'Termék', value: 'prod_name' },
           { text: 'Oktatás', value: 'oktatas' },
           { text: 'Gyakorlat', value: 'gyakorlat' },
           { text: 'Tankönyv', value: 'tankonyv' },
           { text: 'Bizonylat alapján', value: 'biz_alapjan' },
           { text: 'Vizsgadíj', value: 'vizsgadij' },
           { text: 'Díjbekérő oktatás', value: 'prof_oktatas' },
           { text: 'Leltár', value: 'stock' },
           { text: 'Elmélet', value: 'portal_price' },
           { text: 'E-learning', value: 'elearning_price' },
           { text: 'Érvényesség kezdete', value: 'from_date' },
           { text: 'Érvényesség vége', value: 'to_date' },
        ],
        from_date: '',
        to_date: '',
        id: '',
      }
    },
    methods: {
      getProducts: async function() {
        await this.gettoken()
        this.$http.post('billingo_services/getproducts', {
          token: this.token,
          school_id: this.school_id,
        }).then((response) => {
          this.product_list = response.data
          this.product = ''
          this.stock = ''
          this.oktatas = ''
          this.gyakorlat = ''
          this.book = ''
          this.biz_alap = ''
          this.vizsgadij = ''
          this.prof_okt = ''
          this.nkh_category = ''
          this.product_price = ''
          this.elearning_price = ''
        }, (error) => {
          this.error_text = error.data
        })
      },
      newProduct() {
          this.id = null
          this.product = ''
          this.stock = ''
          this.oktatas = ''
          this.gyakorlat = ''
          this.book = ''
          this.biz_alap = ''
          this.vizsgadij = ''
          this.prof_okt = ''
          this.nkh_category = ''
          this.product_price = ''
          this.elearning_price = ''
          this.from_date = null
          this.to_date = null
      },
      delProduct: async function() {
        if (confirm("Biztosan törli a tételt?")) {
        await this.gettoken()
        this.$http.post('billingo_services/del_products', {
          token: this.token,
          school_id: this.school_id,
          product_id: this.product.id,
        }).then((response) => {
          console.log(response.data)
          this.getProducts()
        }, (error) => {
          this.error_text = error.data
        })
       }
      },
      delProductOccur: async function() {
        if (confirm("Biztosan törli a tételt?")) {
        await this.gettoken()
        this.$http.post('services/delete_product', {
          token: this.token,
          id: this.id,
        }).then((response) => {
          this.getSavedProducts()
        }, (error) => {
          this.error_text = error.data
        })
       }
      },
      getSavedProducts: async function() {
        await this.gettoken()
        this.$http.post('services/product_list', {
          token: this.token,
          school_id: this.school_id,
        }).then((response) => {
          this.saved_products = response.data
        }, (error) => {
          this.error_text = error.data
        })
      },
      getSelectedProduct: async function(id) {
        await this.gettoken()
        this.$http.post('services/product_select', {
          token: this.token,
          product: id,
        }).then((response) => {
          if (response.data != '') {
            this.product = parseInt(response.data[0]['prod_id'])
            this.id = response.data[0]['id']
            this.stock = response.data[0]['stock']
            this.oktatas = response.data[0]['oktatas']
            this.oktatas = response.data[0]['oktatas']
            this.gyakorlat = response.data[0]['gyakorlat']
            this.book = response.data[0]['tankonyv']
            this.biz_alap = response.data[0]['biz_alapjan']
            this.vizsgadij = response.data[0]['vizsgadij']
            this.prof_okt = response.data[0]['prof_oktatas']
            this.nkh_category = parseInt(response.data[0]['category_id'])
            this.product_price = response.data[0]['portal_price']
            this.elearning_price = response.data[0]['elearning_price']
            this.from_date = response.data[0]['from_date']
            this.to_date = response.data[0]['to_date']
          } else {
            this.stock = ''
            this.oktatas = ''
            this.gyakorlat = ''
            this.book = ''
            this.biz_alap = ''
            this.vizsgadij = ''
            this.prof_okt = ''
            this.nkh_category = ''
            this.product_price = ''
            this.elearning_price = ''
          }
        }, (error) => {
          this.error_text = error.data
        })
      },
      savedProductDetails: async function() {
        await this.gettoken()
        this.$http.post('services/save_product_details', {
          token: this.token,
          id: this.id,
          school_id: this.school_id,
          product: this.product,
          stock: this.stock,
          oktatas: this.oktatas,
          gyakorlat: this.gyakorlat,
          book: this.book,
          biz_alap: this.biz_alap,
          vizsgadij: this.vizsgadij,
          prof_oktatas: this.prof_okt,
          category: this.nkh_category, // nkh_course_type_id csere
          product_price: this.product_price,
          elearning_price: this.elearning_price,
          from_date: this.from_date,
          to_date: this.to_date
        }).then((response) => {
          this.id = response.data['id']
          this.getSavedProducts()
          this.getSelectedProduct(this.id)
        }, (error) => {
          this.error_text = error.data
        })
      },
      saveAsProductDetails: async function() {
        await this.gettoken()
        this.$http.post('services/save_product_details', {
          token: this.token,
          id: null,
          school_id: this.school_id,
          product: this.product,
          stock: this.stock,
          oktatas: this.oktatas,
          gyakorlat: this.gyakorlat,
          book: this.book,
          biz_alap: this.biz_alap,
          vizsgadij: this.vizsgadij,
          prof_oktatas: this.prof_okt,
          category: this.nkh_category, // nkh_course_type_id csere
          product_price: this.product_price,
          elearning_price: this.elearning_price,
          from_date: this.from_date,
          to_date: this.to_date
        }).then((response) => {
          this.id = response.data['id']
          this.getSavedProducts()
          this.getSelectedProduct(this.id)
        }, (error) => {
          this.error_text = error.data
        })
      },
      getSettings: async function() {
        await this.gettoken()
        this.$http.post('services/settings_load', {
          token: this.token,
        }).then((response) => {
          this.dev_settings = response.data
        }, (error) => {
          this.error_text = error.data
        })
      },
      getCategories: async function() {
        await this.gettoken()
        this.$http.post('services/nkh_category_list', {
          token: this.token,
          school_id: this.school_id
        }).then((response) => {
          this.categories = response.data
        }, (error) => {
          this.error_text = error.data
        })
      },
      saveSettings: async function() {
        await this.gettoken()
        this.$http.post('services/settings_save', {
          token: this.token,
          option: this.dev_settings,
        }).then((response) => {
          alert(response.data);
          this.getSettings();
          this.getSavedProducts()
        }, (error) => {
          this.error_text = error.data
        })
      },
      sandboxSetup: async function() {
        await this.gettoken()
        this.$http.post('services/sandbox_setup', {
          token: this.token,
          option: this.dev_settings,
        }).then((response) => {
          alert(response.data);
          this.getProducts()
        }, (error) => {
          this.error_text = error.data
        })
      },
    },
    watch: {
      /*product() {
        this.getSelectedProduct()
      },*/
      school_id() {
        this.getProducts()
        this.getSavedProducts()
        this.getCategories()
      },
    },
    mounted() {
      this.getProducts()
      this.getSavedProducts()
      this.getSettings()
      this.getCategories()
    },
    computed: {
    ...mapFields([
      'ids.customerId',
      'ids.contractId',
      'ids.school_id',
      'ids.billingo_id',
      'ids.iroda',
      'ids.fbuser',
      'ids.username',
      'functions.dev_settings',
  ]),
  },
  }
</script>

<style lang="css">
</style>
