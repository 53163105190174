<template lang="html">

<div>

  <v-btn
    @click="levetel_dialog = true, getAllExpenses()"
    v-if="admin || fbuser == 'dgabor9@gmail.com'"
    style="top: 120px;"
    fixed
    dark
    fab
    top
    color="red"
    left
    class="d-print-none"
  >
  <v-icon large color="black">checklist_rtl</v-icon>
  </v-btn>

  <div class="d-none d-print-block">
    <p class="text-center title">{{username}}</p>
    <p class="text-center title">({{dates[0]}} - {{dates[1]}})</p>
    <p class="text-center title">Készpénz: {{payments_sum}} Ft (Gyakorlat: {{gyakorlat_sum}} Ft)</p>
    <p class="text-center title">Összesen: {{payments_all}} Ft</p>
    <p class="text-center">Utolsó adatlekérés időpontja: {{get_data_time}}</p>
  </div>

  <v-card class="d-print-none">
    <v-card-title>
      Befizetések<div class="d-print-none">: {{dates[0]}} - {{dates[1]}}</div>
      <v-spacer></v-spacer>
      <v-radio-group v-model="szukitett" row class="d-print-none">
        <v-radio label="Szűkített nézet" :value='true'></v-radio>
        <v-radio label="Részletes nézet" :value='false'></v-radio>
      </v-radio-group>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Keresés"
        single-line
        hide-details
        class="d-print-none"
      ></v-text-field>
    </v-card-title>
  <v-data-table
     :headers="headers"
     :hide-default-header="!szukitett"
     :items="payments"
     :items-per-page="15"
     :search="search"
     :sort-by="['invoice_date']"
     class="d-print-table"
     :loading="table_loader"
     loading-text="Betöltés..."
   >
   <template v-if="!szukitett" v-slot:header="{ props: { headers } }">
        <thead>
          <tr>
            <th>Tanuló név</th>
            <th>Számlázási név</th>
            <th>Születési idő</th>
            <th>Szerződés</th>
            <th>Szerződés típus</th>
            <th>Szerződés dátum</th>
            <th>Szerződéskötés helye</th>
            <th>Összeg</th>
            <th>Tárgy</th>
            <th>Fizetési mód</th>
            <th>Státusz</th>
            <th>Számlaszám</th>
            <th>Befizetés ideje</th>
          </tr>
        </thead>
   </template>
   <template v-if="!szukitett" v-slot:body="{ items }">
     <tbody>
        <tr v-for="item in items" :key="item.payment_id">
          <td>{{ item.full_name }}</td>
          <td>{{ item.billing_name }}</td>
          <td>{{ item.birthdate }}</td>
          <td>{{ item.contract_name }}</td>
          <td>{{ item.contract_type }}</td>
          <td>{{ item.contract_date }}</td>
          <td>{{ item.place_name }}</td>
          <td>{{ item.amount }}</td>
          <td>{{ item.item_name }}</td>
          <td>{{ item.payment_type }}</td>
          <td>{{ item.status }}</td>
          <td>{{ item.invoice_number }}</td>
          <td>{{ item.invoice_date }}</td>
        </tr>
      </tbody>
   </template>
  </v-data-table>
  </v-card>

  <table border="1" class="d-none d-print-block" style="width:100%">
    <thead>
      <tr>
        <th>Számlázási név</th>
        <th>Születési idő</th>
        <th>Szerződés</th>
        <th>Összeg</th>
        <th>Tárgy</th>
        <th>Fizetési mód</th>
        <th>Befizetés ideje</th>
      </tr>
    </thead>
    <tbody>
       <tr v-for="item in payments" :key="item.payment_id">
         <td>{{ item.billing_name }}</td>
         <td>{{ item.birthdate }}</td>
         <td>{{ item.contract_name }}</td>
         <td>{{ item.amount }}</td>
         <td>{{ item.item_name }}</td>
         <td>{{ item.payment_type }}</td>
         <td>{{ item.invoice_date }}</td>
       </tr>
     </tbody>
  </table>
  <br>
  <v-card class="d-print-none">
    <v-card-title>
      Gyakorlati tandíj<div class="d-print-none">: {{dates[0]}} - {{dates[1]}}</div>
      <v-spacer></v-spacer>
      <v-radio-group v-model="szukitett" row class="d-print-none">
        <v-radio label="Szűkített nézet" :value='true'></v-radio>
        <v-radio label="Részletes nézet" :value='false'></v-radio>
      </v-radio-group>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Keresés"
        single-line
        hide-details
        class="d-print-none"
      ></v-text-field>
    </v-card-title>
  <v-data-table
     :headers="headers"
     :hide-default-header="!szukitett"
     :items="payments_gyak"
     :items-per-page="15"
     :search="search"
     :sort-by="['invoice_date']"
     class="d-print-table"
     :loading="table_loader"
     loading-text="Betöltés..."
   >
   <template v-if="!szukitett" v-slot:header="{ props: { headers } }">
        <thead>
          <tr>
            <th>Tanuló név</th>
            <th>Számlázási név</th>
            <th>Születési idő</th>
            <th>Szerződés</th>
            <th>Szerződés típus</th>
            <th>Szerződés dátum</th>
            <th>Szerződéskötés helye</th>
            <th>Összeg</th>
            <th>Tárgy</th>
            <th>Fizetési mód</th>
            <th>Státusz</th>
            <th>Számlaszám</th>
            <th>Befizetés ideje</th>
          </tr>
        </thead>
   </template>
   <template v-if="!szukitett" v-slot:body="{ items }">
     <tbody>
        <tr v-for="item in items" :key="item.payment_id">
          <td>{{ item.full_name }}</td>
          <td>{{ item.billing_name }}</td>
          <td>{{ item.birthdate }}</td>
          <td>{{ item.contract_name }}</td>
          <td>{{ item.contract_type }}</td>
          <td>{{ item.contract_date }}</td>
          <td>{{ item.place_name }}</td>
          <td>{{ item.amount }}</td>
          <td>{{ item.item_name }}</td>
          <td>{{ item.payment_type }}</td>
          <td>{{ item.status }}</td>
          <td>{{ item.invoice_number }}</td>
          <td>{{ item.invoice_date }}</td>
        </tr>
      </tbody>
   </template>
  </v-data-table>
  </v-card>

  <div class="d-none d-print-block">
  <center>
    <h2>Gyakorlati tandíj</h2>
  </center>
  <br>
  <table border="1" class="d-none d-print-block" style="width:100%">
    <thead>
      <tr>
        <th>Számlázási név</th>
        <th>Születési idő</th>
        <th>Szerződés</th>
        <th>Összeg</th>
        <th>Tárgy</th>
        <th>Fizetési mód</th>
        <th>Befizetés ideje</th>
      </tr>
    </thead>
    <tbody>
       <tr v-for="item in payments_gyak" :key="item.payment_id">
         <td>{{ item.billing_name }}</td>
         <td>{{ item.birthdate }}</td>
         <td>{{ item.contract_name }}</td>
         <td>{{ item.amount }}</td>
         <td>{{ item.item_name }}</td>
         <td>{{ item.payment_type }}</td>
         <td>{{ item.invoice_date }}</td>
       </tr>
     </tbody>
  </table>
  </div>

  <br>
  <v-card class="d-print-none">
    <v-card-title>
      Tansegédletek
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search_books"
        append-icon="search"
        label="Keresés"
        single-line
        hide-details
        class="d-print-none"
      ></v-text-field>
    </v-card-title>
  <v-data-table
     :headers="book_headers"
     :items="books"
     :items-per-page="15"
     :search="search_books"
     :sort-by="['invoice_date']"
     class="d-print-table"
     :loading="table_loader"
     loading-text="Betöltés..."
   >
  </v-data-table>
  </v-card>

  <div class="d-none d-print-block">
    <center>
    <h2>Tansegédletek</h2>
    </center>
    <br>
  <table border="1" style="width:100%">
    <thead>
      <tr>
        <th>Számlázási név</th>
        <th>Születési idő</th>
        <th>Összeg</th>
        <th>Tárgy</th>
        <th>Fizetési mód</th>
        <th>Befizetés ideje</th>
      </tr>
    </thead>
    <tbody>
       <tr v-for="item in books" :key="item.payment_id">
         <td>{{ item.billing_name }}</td>
         <td>{{ item.birthdate }}</td>
         <td>{{ item.amount }}</td>
         <td>{{ item.item_name }}</td>
         <td>{{ item.payment_type }}</td>
         <td>{{ item.invoice_date }}</td>
       </tr>
     </tbody>
  </table>
  </div>

  <br>
  <v-card v-if="post_invoices != ''" class="d-print-none">
    <v-card-title>
      Utólag kiállított számla (korábbi befizetés alapján)
      <v-spacer></v-spacer>
    </v-card-title>
  <v-data-table
     :headers="post_invoices_headers"
     :items="post_invoices"
     :items-per-page="15"
     :sort-by="['invoice_date']"
     class="d-print-table"
     :loading="table_loader"
     loading-text="Betöltés..."
   >
  </v-data-table>
  </v-card>

  <div class="d-none d-print-block" v-if="post_invoices != ''">
    <center>
    <h2>Utólag kiállított számla (korábbi befizetés alapján)</h2>
    </center>
    <br>
  <table border="1" style="width:100%">
    <thead>
      <tr>
        <th>Számlázási név</th>
        <th>Születési idő</th>
        <th>Szerződés</th>
        <th>Összeg</th>
        <th>Tárgy</th>
        <th>Fizetési mód</th>
        <th>Befizetés ideje</th>
      </tr>
    </thead>
    <tbody>
       <tr v-for="item in post_invoices" :key="item.payment_id">
         <td>{{ item.billing_name }}</td>
         <td>{{ item.birthdate }}</td>
         <td>{{ item.contract_name }}</td>
         <td>{{ item.amount }}</td>
         <td>{{ item.item_name }}</td>
         <td>{{ item.payment_type }}</td>
         <td>{{ item.invoice_date }}</td>
       </tr>
     </tbody>
  </table>
  </div>

  <br>
  <v-card v-if="no_payments != ''" class="d-print-none">
    <v-card-title>
      Szerződéskötések (irodai befizetés nélkül)
      <v-spacer></v-spacer>
    </v-card-title>
  <v-data-table
     :headers="no_payments_headers"
     :items="no_payments"
     :items-per-page="15"
     :sort-by="['invoice_date']"
     class="d-print-table"
     :loading="table_loader"
     loading-text="Betöltés..."
   >
  </v-data-table>
  </v-card>
  <br>

  <div class="d-none d-print-block" v-if="no_payments != ''">
    <center>
    <h2>Szerződéskötések (irodai befizetés nélkül)</h2>
    </center>
    <br>
  <table border="1" style="width:100%">
    <thead>
      <tr>
        <th>Tanuló név</th>
        <th>Születési idő</th>
        <th>Szerződés</th>
        <th>Szerződés dátum</th>
        <th>Szerződés típus</th>
      </tr>
    </thead>
    <tbody>
       <tr v-for="item in no_payments" :key="item.payment_id">
         <td>{{ item.full_name }}</td>
         <td>{{ item.birthdate }}</td>
         <td>{{ item.contract_name }}</td>
         <td>{{ item.contract_date }}</td>
         <td>{{ item.label }}</td>
       </tr>
     </tbody>
  </table>
  </div>

  <v-container class="title text-center d-print-none">
    Készpénz: {{payments_sum}} Ft (Gyakorlat: {{gyakorlat_sum}} Ft)<br>
    Összesen: {{payments_all}} Ft
  </v-container>
  <br>
  <v-container class="d-print-none">
  <v-card v-if="fbuser != 'suli@csigajogsi.hu'">
    <v-card-title>
      Egyéb bevétel/kiadás {{most}}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search_expenses"
        append-icon="search"
        label="Keresés"
        single-line
        hide-details
        class="d-print-none"
      ></v-text-field>
    </v-card-title>
  <v-data-table
      v-model="selected"
     :headers="expenses_headers"
     :items="expenses"
     :items-per-page="15"
     class="d-print-table"
     :loading="table_loader"
     loading-text="Betöltés..."
     :search="search_expenses"
     show-select
   >

   <template
      v-slot:item.kifiz="{ item }"
    >
          <v-checkbox
            v-model="item.kifiz"
          ></v-checkbox>
    </template>

    <template
       v-slot:item.kifizdate="{ item }"
     >
      <input type="date" v-model="item.kifizdate">
     </template>

     <template
        v-slot:item.megjegyzes="{ item }"
      >
        <input type="text" v-model="item.megjegyzes">
      </template>

      <template v-slot:item.controls="props">
        <v-btn :disabled="!props.item.kifizdate" class="mx-2" outlined color="primary" @click="payExpenses(props.item)">
          Mentés
        </v-btn>
      </template>

    <template v-slot:item.targy="props">
      <v-edit-dialog
        :return-value.sync="props.item.targy"
        @save="modExpenses(props.item)"
        save-text="Mentés"
        cancel-text="Mégsem"
        large
        persistent
      > {{ props.item.targy }}
        <template v-slot:input>
          <v-text-field
            v-model="props.item.targy"
            label="Megnevezés szerkesztése"
            single-line
            :disabled="props.item.regdatum != most && props.item.regdatum != yesterday && admin != 1"
          ></v-text-field>
        </template>
      </v-edit-dialog>
    </template>
    <template v-slot:item.amount="props">
      <v-edit-dialog
        :return-value.sync="props.item.amount"
        @save="modExpenses(props.item)"
        save-text="Mentés"
        cancel-text="Mégsem"
        large
        persistent
      > {{ props.item.amount }}
        <template v-slot:input>
          <v-text-field
            v-model="props.item.amount"
            label="Összeg szerkesztése"
            single-line
            type="number"
            :disabled="props.item.regdatum != most && props.item.regdatum != yesterday && admin != 1"
          ></v-text-field>
        </template>
      </v-edit-dialog>
    </template>
    <template v-slot:item.regdatum="props">
      <v-edit-dialog
        :return-value.sync="props.item.regdatum"
        @save="modExpenses(props.item)"
        save-text="Mentés"
        cancel-text="Mégsem"
        large
        persistent
      > {{ props.item.regdatum }}
        <template v-slot:input>
          <v-text-field
            v-model="props.item.regdatum"
            label="Dátum szerkesztése"
            single-line
            :disabled="props.item.regdatum != most && props.item.regdatum != yesterday && admin != 1"
          ></v-text-field>
        </template>
      </v-edit-dialog>
    </template>
    <template v-slot:item.delete="props">
      <v-btn class="ma-2 d-print-none" outlined fab small color="red" @click="delete_dialog = true, expenses_del_id = props.item.id, expenses_del_subject = props.item.targy" :disabled="props.item.regdatum != most && props.item.regdatum != yesterday && admin != 1">
        <v-icon>delete</v-icon>
      </v-btn>
    </template>
  </v-data-table>

  <v-row>
    <v-col cols="12" md="4">
      <v-btn :disabled="selected == ''" class="mx-2" outlined color="primary" @click="printExpenses()">
        Átvételi elismervény
      </v-btn>
    </v-col>
    <v-col cols="12" md="4">
      <v-btn :disabled="dates == ''" class="mx-2" outlined color="primary" @click="printExpensesAll()">
        Lista nyomtatása
      </v-btn>
    </v-col>
  </v-row>

  <v-card-text>
  <v-container class="d-print-none">
    <v-form
    ref="expenses_form"
    v-model="expenses_valid"
    lazy-validation
    >
  <v-row>
    <v-col cols="12" md="4">
      <span>Megnevezés</span>
      <v-text-field
        :value="subject"
        @change="subject = $event"
        outlined
        flat
        solo
        :rules="[rules.required]"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="4">
      <span>Összeg</span>
      <v-text-field
        :value="amount"
        @change="amount = $event"
        outlined
        flat
        solo
        type="number"
        :rules="[rules.required]"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="4">
      <v-btn
        color="primary"
        class="mt-8"
        @click="validate()"
      >
        Hozzáad
        <v-icon right dark>add_circle_outline</v-icon>
      </v-btn>
    </v-col>
  </v-row>
  </v-form>
  </v-container>
  </v-card-text>
  </v-card>
  </v-container>

      <v-dialog
        v-model="levetel_dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar
            dark
            color="red"
          >
            <v-toolbar-title>Levett összegek</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn
                icon
                dark
                @click="levetel_dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-container>
            <v-row justify="center" class="d-print-none">
              <v-radio-group v-model="allexpenses_select" row>
                <v-radio label="Függő" :value="0"></v-radio>
                <v-radio label="Archív" :value="1"></v-radio>
              </v-radio-group>
            </v-row>
          <v-text-field
            v-model="search_expenses_fromAll"
            clearable
            label="Keresés"
            single-line
            hide-details
            class="d-print-none"
          ></v-text-field>
          </v-container>
          <v-data-table
             :headers="expenses_headers"
             :items="expenses_all"
             :items-per-page="15"
             class="d-print-table"
             :loading="table_loader"
             loading-text="Betöltés..."
             :search="search_expenses_fromAll"
           >

           <template
              v-slot:item.kifiz="{ item }"
            >
                  <v-checkbox
                    :disabled="!admin && fbuser != 'dgabor9@gmail.com'"
                    v-model="item.kifiz"
                  ></v-checkbox>
            </template>

            <template
               v-slot:item.kifizdate="{ item }"
             >
              <input :disabled="!admin && fbuser != 'dgabor9@gmail.com'" type="date" v-model="item.kifizdate">
             </template>

             <template
                v-slot:item.megjegyzes="{ item }"
              >
                <input :disabled="!admin && fbuser != 'dgabor9@gmail.com'" type="text" v-model="item.megjegyzes">
              </template>

              <template v-slot:item.controls="props">
                <v-btn v-if="admin || fbuser == 'dgabor9@gmail.com'" :disabled="!props.item.kifizdate || !props.item.kifiz" class="mx-2" outlined color="primary" @click="payExpensesFromAll(props.item)">
                  Mentés
                </v-btn>
              </template>

            <template v-slot:item.delete="props">
              <v-btn class="ma-2 d-print-none" outlined fab small color="red" @click="delete_dialog = true, expenses_del_id = props.item.id, expenses_del_subject = props.item.targy" :disabled="props.item.regdatum != most && props.item.regdatum != yesterday && admin != 1">
                <v-icon>delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>

        </v-card>
      </v-dialog>

  <div class="d-none d-print-block">
    <center>
    <h2>Egyéb bevétel/kiadás</h2>
    </center>
    <br>
  <table border="1" style="width:100%">
    <thead>
      <tr>
        <th>Dátum</th>
        <th>Megnevezés</th>
        <th>Összeg</th>
      </tr>
    </thead>
    <tbody>
       <tr v-for="item in expenses" :key="item.id">
         <td>{{ item.regdatum }}</td>
         <td>{{ item.targy }}</td>
         <td>{{ item.amount }}</td>
       </tr>
     </tbody>
  </table>
  </div>

  <v-dialog v-model="delete_dialog" persistent max-width="290">
     <v-card>
       <v-card-title class="headline">Törlés</v-card-title>
       <v-card-text>Biztosan törli a tételt? ({{expenses_del_subject}})</v-card-text>
       <v-card-actions>
         <v-spacer></v-spacer>
         <v-btn color="green darken-1" text @click="delExpenses(), delete_dialog = false">Igen</v-btn>
         <v-btn color="green darken-1" text @click="delete_dialog = false">Nem</v-btn>
       </v-card-actions>
     </v-card>
   </v-dialog>
</div>
</template>

<script>
import {mapFields} from 'vuex-map-fields';
import { EventBus } from '../event-bus';

  export default {
    data () {
      return {
        headers: [
          { text: 'Tanuló név', value: 'full_name', width: 150 },
          { text: 'Számlázási név', value: 'billing_name', width: 150 },
          { text: 'Születési idő', value: 'birthdate', width: 120 },
          { text: 'Szerződés', value: 'contract_name', width: 120 },
          { text: 'Szerződés típus', value: 'contract_type', width: 150 },
          { text: 'Összeg', value: 'amount', width: 120 },
          { text: 'Tárgy', value: 'item_name' },
          { text: 'Fizetési mód', value: 'payment_type', width: 120 },
          { text: 'Státusz', value: 'status' },
          { text: 'Befizetés ideje', value: 'invoice_date', width: 150 },
        ],
        payments: [],
        book_headers: [
          { text: 'Tanuló név', value: 'full_name' },
          { text: 'Számlázási név', value: 'billing_name' },
          { text: 'Születési idő', value: 'birthdate' },
          { text: 'Összeg', value: 'amount' },
          { text: 'Tárgy', value: 'item_name' },
          { text: 'Fizetési mód', value: 'payment_type' },
          { text: 'Státusz', value: 'status' },
          { text: 'Számlaszám', value: 'invoice_number' },
          { text: 'Befizetés ideje', value: 'invoice_date' },
        ],
        books: [],
        post_invoices_headers: [
          { text: 'Tanuló név', value: 'full_name' },
          { text: 'Számlázási név', value: 'billing_name' },
          { text: 'Születési idő', value: 'birthdate' },
          { text: 'Szerződés', value: 'contract_name' },
          { text: 'Szerződés dátum', value: 'contract_date' },
          { text: 'Összeg', value: 'amount' },
          { text: 'Tárgy', value: 'item_name' },
          { text: 'Fizetési mód', value: 'payment_type' },
          { text: 'Számlaszám', value: 'invoice_number' },
          { text: 'Befizetés ideje', value: 'invoice_date' },
        ],
        no_payments_headers: [
          { text: 'Tanuló név', value: 'full_name' },
          { text: 'Születési idő', value: 'birthdate' },
          { text: 'Szerződés', value: 'contract_name' },
          { text: 'Szerződés dátum', value: 'contract_date' },
          { text: 'Szerződés típus', value: 'contract_type' },
          { text: 'Összeg', value: 'amount' },
          { text: 'Tárgy', value: 'item_name' },
          { text: 'Fizetési mód', value: 'payment_type' },
          { text: 'Státusz', value: 'status' },
          { text: 'Befizetés ideje', value: 'invoice_date' },
        ],
        szukitett: true,
        search: '',
        search_expenses: '',
        search_books: '',
        table_loader: false,
        payments_sum: '',
        gyakorlat_sum: '',
        payments_all: '',
        post_invoices: [],
        no_payments: [],
        expenses: [],
        expenses_all: [],
        payments_gyak: [],
        expenses_headers: [
          { text: 'Dátum', value: 'regdatum', width: 120 },
          { text: 'Megnevezés', value: 'targy', width: 150 },
          { text: 'Összeg', value: 'amount', width: 120 },
          { text: 'Műveletek', value: 'delete', sortable: false },
          { text: 'Kiegyenlítve', value: 'kifiz', width: 120, sortable: false },
          { text: 'Kifizetés ideje', value: 'kifizdate' },
          { text: 'Megjegyzés', value: 'megjegyzes', sortable: false },
          { text: "", value: "controls", sortable: false }
        ],
        today: new Date().toISOString().substr(0, 10),
        most: new Date().toISOString().substr(0, 10),
        yesterday: new Date(Date.now() - 1 * 86400000 - new Date().getTimezoneOffset() * 60000).toISOString().split('T')[0],
        amount: '',
        subject: '',
        response: '',
        expenses_valid: true,
        rules: {
            required: value => !!value || 'Kötelező mező!',
          },
        expenses_del_id: '',
        expenses_del_subject: '',
        delete_dialog: false,
        get_data_time: '',
        levetel_dialog: false,
        search_expenses_fromAll: '',
        allexpenses_select: 0,
        selected: []
      }
    },
    methods: {
      getPayments: async function() {
        this.table_loader = true
        await this.gettoken()
        this.$http.post('services/payments', {
          token: this.token,
          user: this.iroda,
          dates: this.dates,
        }).then((response) => {
          this.table_loader = false
          this.payments = response.data
        }, (error) => {
          this.error_text = error.data
        })
      },
      getGyakPayments: async function() {
        this.table_loader = true
        await this.gettoken()
        this.$http.post('services/payments_gyakorlat', {
          token: this.token,
          user: this.iroda,
          dates: this.dates,
        }).then((response) => {
          this.table_loader = false
          this.payments_gyak = response.data
        }, (error) => {
          this.error_text = error.data
        })
      },
      getBooks: async function() {
        this.table_loader = true
        await this.gettoken()
        this.$http.post('services/tankonyvek', {
          token: this.token,
          user: this.iroda,
          dates: this.dates,
        }).then((response) => {
          this.table_loader = false
          this.books = response.data
        }, (error) => {
          this.error_text = error.data
        })
      },
      getPostInvoices: async function() {
        this.table_loader = true
        await this.gettoken()
        this.$http.post('services/post_invoices', {
          token: this.token,
          user: this.iroda,
          dates: this.dates,
        }).then((response) => {
          this.table_loader = false
          this.post_invoices = response.data
        }, (error) => {
          this.error_text = error.data
        })
      },
      getNoPayments: async function() {
        this.table_loader = true
        await this.gettoken()
        this.$http.post('services/szerzodes_check', {
          token: this.token,
          user: this.iroda,
          dates: this.dates,
          user_place_id: this.user_place_id
        }).then((response) => {
          this.table_loader = false
          this.no_payments = response.data
        }, (error) => {
          this.error_text = error.data
        })
      },
      sumPayments: async function() {
        await this.gettoken()
        this.$http.post('services/szum_payments_230110', {
          token: this.token,
          iroda: this.iroda,
          dates: this.dates,
        }).then((response) => {
          this.payments_sum = response.data['cash']
          this.payments_all = response.data['all']
          this.gyakorlat_sum = response.data['gyakorlat']
        }, (error) => {
          this.error_text = error.data
        })
      },
      getExpenses: async function() {
        await this.gettoken()
        this.$http.post('services/get_expenses', {
          token: this.token,
          user: this.iroda,
          dates: this.dates,
        }).then((response) => {
          this.expenses = response.data
          this.selected = []
        }, (error) => {
          this.error_text = error.data
        })
      },
      getAllExpenses: async function() {
        this.dates.sort();
        this.expenses_all = []
        await this.gettoken()
        this.$http.post('services/get_expenses_all', {
          token: this.token,
          dates: this.dates,
          select: this.allexpenses_select
        }).then((response) => {
          this.expenses_all = response.data
        }, (error) => {
          this.error_text = error.data
        })
      },
      saveExpenses: async function() {
        await this.gettoken()
        this.$http.post('services/add_expenses', {
          token: this.token,
          user: this.iroda,
          regdatum: this.today,
          amount: this.amount,
          subject: this.subject
        }).then((response) => {
          this.response = response.data
          this.getExpenses()
          this.subject = null
          this.amount = null
          this.resetValidation()
          this.sumPayments()
        }, (error) => {
          this.error_text = error.data
        })
      },
      modExpenses: async function(value) {
        await this.gettoken()
        this.$http.post('services/update_expenses', {
          token: this.token,
          regdatum: value.regdatum,
          amount: value.amount,
          subject: value.targy,
          id: value.id
        }).then((response) => {
          this.response = response.data
          this.getExpenses()
          this.sumPayments()
        }, (error) => {
          this.error_text = error.data
        })
      },
      delExpenses: async function() {
        await this.gettoken()
        this.$http.post('services/delete_expenses', {
          token: this.token,
          id: this.expenses_del_id
        }).then((response) => {
          this.response = response.data
          this.getExpenses()
          this.sumPayments()
        }, (error) => {
          this.error_text = error.data
        })
      },
      payExpenses: async function(value) {
        console.log(value);
        await this.gettoken()
        this.$http.post('services/pay_expenses', {
          token: this.token,
          id: value.id,
          kifiz: value.kifiz,
          kifizdate: value.kifizdate,
          megjegyzes: value.megjegyzes
        }).then((response) => {
          this.response = response.data
          this.getExpenses()
        }, (error) => {
          this.error_text = error.data
        })
      },
      payExpensesFromAll: async function(value) {
        console.log(value);
        await this.gettoken()
        this.$http.post('services/pay_expenses', {
          token: this.token,
          id: value.id,
          kifiz: value.kifiz,
          kifizdate: value.kifizdate,
          megjegyzes: value.megjegyzes
        }).then((response) => {
          this.response = response.data
          this.getAllExpenses()
        }, (error) => {
          this.error_text = error.data
        })
      },
      printExpenses: async function() {
        await this.gettoken()
        this.$http.post('services/print_expenses', {
          token: this.token,
          expenses: this.selected
        }, {responseType: 'blob'}).then((response) => {
          this.preloader = false
          const file = new Blob(
          [response.data],
          {type: 'application/pdf'});
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }, (error) => {
          this.error_text = error.data
        })
      },
      printExpensesAll: async function() {
        await this.gettoken()
        this.$http.post('services/print_expenses_all', {
          token: this.token,
          user: this.iroda,
          dates: this.dates
        }, {responseType: 'blob'}).then((response) => {
          this.preloader = false
          const file = new Blob(
          [response.data],
          {type: 'application/pdf'});
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }, (error) => {
          this.error_text = error.data
        })
      },
      refresh() {
        this.getPayments()
        this.getBooks()
        this.sumPayments()
        this.getPostInvoices()
        this.getNoPayments()
        this.getExpenses()
        this.getGyakPayments()
        this.get_data_time = new Date().toLocaleString("hu-HU", {timeZone: "Europe/Budapest"})
      },
      validate () {
        if (this.$refs.expenses_form.validate()) {
          this.saveExpenses()
        }
      },
      reset () {
        this.$refs.expenses_form.reset()
      },
      resetValidation () {
        this.$refs.expenses_form.resetValidation()
      },
    },
    mounted() {
      this.refresh()
    },
    watch: {
      expenses_select(value) {
          if (value == 'elszam') {
            this.refresh()
          }
      },
      allexpenses_select() {
        this.getAllExpenses()
      }
    },
    created() {
      EventBus.$on('elszamRefresh', () => {
            this.refresh();
      })
    },
    computed: {
    ...mapFields([
      'ids.fbuser',
      'ids.username',
      'ids.user_place_id',
      'ids.iroda',
      'ids.admin',
      'functions.dates',
      'functions.expenses_select',
    ]),
    },
  }
</script>

<style lang="css">
</style>
