<template lang="html">
  <div class="levet-component">
    <v-container>

    <div v-if="fbuser != 'suli@csigajogsi.hu'">

    <v-form
      ref="expense_form"
      v-model="expense_valid"
    >
    <v-card
      outlined
      shaped
      class="mb-2"
    >
    <v-card-title>
      Új levét rögzítés
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="item_name"
            label="Tárgy"
            outlined
            solo
            flat
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="item_sum"
            label="Összeg"
            v-mask="'######'"
            outlined
            solo
            flat
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-divider></v-divider><br>
      <v-row v-for="(item, index) in items" v-bind:key="index">
        <v-col cols="12" md="4">
          <v-text-field
            v-model="item.qty"
            label="Levéltel"
            outlined
            solo
            flat
            v-mask="'-######'"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            v-model="item.office"
            :items="office_names"
            return-object
            label="Elszámolás"
            outlined
            flat
            solo
            :rules="[rules.required]"
          ></v-select>
        </v-col>
        <v-col cols="12" md="4">
          <v-menu
            ref="menu_expense"
            v-model="item.menu_expense"
            :close-on-content-click="false"
            :return-value.sync="item.date_expense"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="item.date_expense"
                append-icon="event"
                label="Elszámolás dátuma"
                readonly
                outlined
                flat
                solo
                v-on="on"
                :rules="[rules.required]"
              ></v-text-field>
            </template>
            <v-date-picker v-model="item.date_expense" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="item.menu_expense = false">Mégsem</v-btn>
              <v-btn text color="primary" @click="$refs.menu_expense[index].save(item.date_expense)">OK</v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="9">
          <v-text-field
            v-model="item.item_comment"
            label="Megjegyzés"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3" v-if="!selected_id">
        <v-btn @click="removeLicenseLine(index, item.line_id)" color="red" outlined class="mt-2">
          törlés
          <v-icon right dark>delete</v-icon>
        </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3" v-if="selected_id">
        <v-btn @click="updateExpenses()" color="primary" outlined class="mt-2" :disabled="!allowed || !expense_valid">
          módosít
          <v-icon right dark>delete</v-icon>
        </v-btn>
        </v-col>
        <v-col cols="12" md="3" v-if="selected_id">
        <v-btn @click="delExpenses()" color="red" outlined class="mt-2" :disabled="!allowed">
          törlés
          <v-icon right dark>delete</v-icon>
        </v-btn>
        </v-col>
      <v-col>
        <v-btn v-if="!selected_id" outlined color="primary" dark @click="addItem()">
          Új sor
          <v-icon right dark>add</v-icon>
        </v-btn>
      </v-col>
      </v-row>
    </v-card-text>
    </v-card>
    <v-row>
    <v-col cols="4">
      <v-btn v-if="!selected_id" color="primary" @click="saveExpenses()" :disabled="!expense_valid">
        Rögzít
        <v-icon right dark>save</v-icon>
      </v-btn>
      <v-btn v-if="selected_id" color="primary" @click="newItem()">
        Mégsem
        <v-icon right dark>cancel</v-icon>
      </v-btn>
    </v-col>
    <v-col cols="4">
      <v-btn outlined color="green" @click="getExpenses()">
        frissít
        <v-icon right dark>refresh</v-icon>
      </v-btn>
    </v-col>
    </v-row>
    </v-form>

  </div>
  <br>
  <v-card
  outlined
  shaped
  class="mb-2"
  >
    <v-card-title>
      Utólagos levétek
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Keresés"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="levetek"
      :search="search"
      @click:row="items = [], selectItem($event)"
      show-expand
    >
      <template v-slot:item.data-table-expand="{ item }">
        <v-btn
          icon
          @click="printExpenses(item)"
        >
          <v-icon>print</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
    </v-container>

    <v-snackbar
      v-model="snackbar"
    >
      {{ response_text }}

        <v-btn
          color="pink"
          text
          @click="snackbar = false"
        >
          bezár
        </v-btn>
    </v-snackbar>

  </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields';

  export default {
    data () {
      return {
        items: [],
        levetek: [],
        search: '',
        headers: [
          { text: 'Tárgy', value: 'targy' },
          { text: 'Összeg', value: 'total_amount' },
          { text: 'Levét', value: 'amount' },
          { text: 'Iroda', value: 'iroda' },
          { text: 'Elszámolás', value: 'regdatum' },
          { text: 'Levonó iroda', value: 'levono' },
          { text: 'Levonás ideje', value: 'regido' },
        ],
        office_names: [
          { text: 'Keleti', value: 'keleti' },
          { text: 'Sz. István', value: 'sztistvan' },
          { text: 'Fejér Lipót', value: 'kelenfold' },
          { text: 'Haller', value: 'haller' },
          { text: 'Helsinki', value: 'pesterzsebet' },
          { text: 'Üllői út', value: 'ulloi' },
        ],
        office: '',
        expense_valid: true,
        menu_expense: false,
        item_name: '',
        item_sum: '',
        selected_id: '',
        selected_date: '',
        response_text: '',
        snackbar: false,
        rules: {
            required: value => !!value || 'Kötelező mező!',
        },
      }
    },
    methods: {
      addItem: function () {
        this.items.push({
          qty: null,
          office: null,
          date_expense: null,
          item_comment: null
        })
      },
      removeLicenseLine (lineId) {
        this.items.splice(lineId, 1)
      },
      getExpenses: async function() {
        await this.gettoken()
        this.$http.post('services/utolagos_levet', {
          token: this.token,
        }).then((response) => {
          this.levetek = response.data
        }, (error) => {
          this.error_text = error.data
        })
      },
      saveExpenses: async function() {
       if (confirm('Biztosan rögzíti a tételeket?')) {
        await this.gettoken()
        this.$http.post('services/utolagos_levet_save_210520', {
          token: this.token,
          user: this.iroda,
          items: this.items,
          amount: this.item_sum,
          subject: this.item_name
        }).then((response) => {
          this.snackbar = true
          this.response_text = response.data
          this.getExpenses()
          this.newItem()
        }, (error) => {
          this.error_text = error.data
        })
       }
      },
      delExpenses: async function() {
        if (confirm('Biztosan törölni szeretné a sort?')) {
          await this.gettoken()
          this.$http.post('services/utolagos_archive', {
            token: this.token,
            id: this.selected_id
          }).then((response) => {
            this.snackbar = true
            this.response_text = response.data
            this.getExpenses()
            this.newItem()
          }, (error) => {
            this.error_text = error.data
          })
        }
      },
      updateExpenses: async function() {
       if (confirm('Biztosan módosítani szeretné a sort?')) {
        await this.gettoken()
        this.$http.post('services/utolagos_update_210520', {
          token: this.token,
          user: this.iroda,
          items: this.items,
          amount: this.item_sum,
          subject: this.item_name,
          id: this.selected_id
        }).then((response) => {
          this.snackbar = true
          this.response_text = response.data
          this.getExpenses()
          this.newItem()
        }, (error) => {
          this.error_text = error.data
        })
       }
      },
      printExpenses: async function(datas) {
        await this.gettoken()
        this.$http.post('services/utolagos_print', {
          token: this.token,
          iroda: datas.iroda,
          date: datas.regdatum,
          amount: datas.amount,
          levono: datas.levono,
          code: datas.utolagos,
          idopont: datas.regido,
          targy: datas.targy,
          sum: datas.total_amount
        }, {responseType: 'blob'}).then((response) => {
          this.preloader = false
          const file = new Blob(
          [response.data],
          {type: 'application/pdf'});
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }, (error) => {
          this.error_text = error.data
        })
      },
      selectItem (value) {
        this.selected_id = value.id
        this.selected_date = value.regido
        this.item_name = value.targy
        this.item_sum = value.total_amount
        this.items.push({
          qty: value.amount,
          office: value.iroda,
          date_expense: value.regdatum,
          item_comment: value.megjegyzes
        })
      },
      newItem() {
        this.items = []
        this.selected_id = null
        this.item_name = null
        this.item_sum = null
        this.addItem()
        this.resetValidation()
      },
      resetValidation () {
        this.$refs.expense_form.resetValidation()
      },
      teszt(id) {
        console.log(id);
      }
    },
    mounted() {
      this.addItem()
      this.getExpenses()
    },
    computed: {
    ...mapFields([
      'ids.fbuser',
      'ids.username',
      'ids.user_place_id',
      'ids.iroda',
      'ids.admin',
    ]),
    allowed() {
      var today = new Date();
      var yesterday = new Date();
      var custom_date = new Date(this.selected_date);

      yesterday.setDate(today.getDate()-1);

      if (custom_date < yesterday) {
      return false;
      } else {
      return true;
      }
    }
    },
  }
</script>

<style lang="css">
</style>
