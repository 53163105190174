<template lang="html">
<div>
 <v-container>
  <v-card class="d-print-none">
   <v-container>
     <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
    <v-row>
    <v-col cols="12" md="6">
      <v-menu ref="start_date_menu" v-model="start_date_menu" :close-on-content-click="false" :return-value.sync="start_date" transition="scale-transition" offset-y  min-width="290px">
        <template v-slot:activator="{ on }">
          <v-text-field v-model="start_date" label="Kezdő dátum" append-icon="event" readonly v-on="on" outlined>
          </v-text-field>
        </template>
        <v-date-picker v-model="start_date" no-title scrollable first-day-of-week=1>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="start_date_menu = false">Mégsem</v-btn>
          <v-btn text color="primary" @click="$refs.start_date_menu.save(start_date)">OK</v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="12" md="6">
      <v-menu ref="end_date_menu" v-model="end_date_menu" :close-on-content-click="false" :return-value.sync="end_date" transition="scale-transition" offset-y  min-width="290px">
        <template v-slot:activator="{ on }">
          <v-text-field :rules="dateRules" v-model="end_date" label="Befejező dátum" append-icon="event" readonly v-on="on" outlined>
          </v-text-field>
        </template>
        <v-date-picker v-model="end_date" no-title scrollable first-day-of-week=1>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="end_date_menu = false">Mégsem</v-btn>
          <v-btn text color="primary" @click="$refs.end_date_menu.save(end_date)">OK</v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
    </v-row>
    <v-container class="red--text">
      {{date_error}}
    </v-container>
     <v-spacer></v-spacer>
     <v-btn :disabled="!valid" text color="primary" @click="refreshAll(), beiratkozok_show = true, felvett_show = false">Lekérdezés</v-btn>
    </v-form>
   </v-container>
  </v-card>
  <br>
  <h2 class="text-center">({{start_date}} - {{end_date}})</h2>
  <br>
  <v-card class="d-print-none" v-if="beiratkozok_show">
    <v-card-title>
      Utalások
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Keresés"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-container>
      (Utolsó bankszinkron időpontja: {{refresh_date}})
    </v-container>
  <v-data-table
   :headers="headers"
   :items="utalasok"
   :items-per-page="15"
   :search="search"
   :loading="table_loader"
   loading-text="Betöltés..."
   sort-by="made_on"
   class="elevation-1"
   :mobile-breakpoint='NaN'
   ></v-data-table>
 </v-card>
</v-container>
</div>
</template>

<script>
import {mapFields} from 'vuex-map-fields';
import { EventBus } from '../event-bus';

  export default {
    data () {
      return {
        utalasok: [],
        beiratkozok_egyeb: [],
        beiratkozok_sum: '',
        tanulok: [],
        start_date_menu: false,
        start_date: new Date().toISOString().substr(0, 10),
        end_date: new Date().toISOString().substr(0, 10),
        end_date_menu: false,
        headers: [
          { text: 'Név', value: 'partner_name', width: 150 },
          { text: 'Közlemény', value: 'comment2', width: 120 },
          { text: 'Összeg', value: 'amount.formatted', width: 150 },
          { text: 'Dátum', value: 'made_on', width: 150 },
        ],
        table_loader: false,
        beiratkozok_show: true,
        felvett_show: false,
        elojegyzes: '',
        dateRules: [
          v => v >= this.start_date || 'A dátum nem lehet kisebb, mint a kezdő dátum!',
        ],
        valid: true,
        date_error: '',
        search: '',
        refresh_date: ''
      }
    },
    methods: {
      getTransfers: async function() {
        this.utalasok = []
        this.table_loader = true
        await this.gettoken()
        this.$http.post('services/utalasok', {
          token: this.token,
          start_date: this.start_date,
          end_date: this.end_date,
          school: this.school_id
        }).then((response) => {
          this.table_loader = false
          this.utalasok = response.data;
          console.log(response.data);
        }, (error) => {
          this.error_text = error.data
        })
      },
      transferStat: async function() {
        this.refresh_date = 'lekérdezés...';
        await this.gettoken()
        this.$http.post('services/utalasok_stat', {
          token: this.token,
          school: this.school_id
        }).then((response) => {
          this.refresh_date = response.data;
        }, (error) => {
          this.error_text = error.data
        })
      },
      refreshAll() {
        this.getTransfers()
      },
      validate () {
        this.$refs.form.validate()
      },
      validate_dates() {
        const dateEnd = new Date(this.end_date);
        const dateStart = new Date(this.start_date);
        let difference = dateEnd.getTime() - dateStart.getTime();
        difference = difference / (1000 * 60 * 60 * 24);
        if (difference > 31) {
          this.date_error = 'A lekérdezett időszak nem lehet egy hónapnál nagyobb!';
          this.valid = false;
        } else {
          this.date_error = '';
          this.valid = true;
        }
      }
    },
    mounted() {
      this.getTransfers()
      this.transferStat()
    },
    watch: {
      start_date() {
        this.validate_dates();
        this.validate();
      },
      end_date() {
        this.validate_dates();
      },
      school_id() {
        this.getTransfers();
        this.transferStat()
      }
    },
    computed: {
    ...mapFields([
      'ids.fbuser',
      'ids.username',
      'ids.user_place_id',
      'ids.iroda',
      'ids.school_id',
      'functions.dates',
      'functions.expenses_select',
    ]),
    },
  }
</script>

<style lang="css">
</style>
