<template lang="html">
  <div class="component-wrapper">
    <v-bottom-navigation
      v-model="invoice_tab"
      grow
      color="primary"
      app
    >
      <v-btn :disabled="invoice_id || mod_invoice_id">
        <span>Ügyfél adatok</span>
        <v-icon>account_circle</v-icon>
      </v-btn>

      <v-btn :disabled="invoice_id">
        <span>Számla adatok</span>
        <v-icon>credit_card</v-icon>
      </v-btn>

      <v-btn>
        <span>Összesítés</span>
        <v-icon>assignment_turned_in</v-icon>
      </v-btn>
    </v-bottom-navigation>
    <Clients v-show="invoice_tab == 0"></Clients>
    <InvoiceDetails v-show="invoice_tab == 1"></InvoiceDetails>
        <div name="PreView" v-show="invoice_tab == 2">
          <v-container>
            <v-alert
              border="top"
              colored-border
              type="error"
              elevation="2"
              v-if="!client_valid"
            >
            Hiányzó adatok: Számlázási cím!
            </v-alert>
            <v-alert
              border="top"
              colored-border
              type="error"
              elevation="2"
              v-if="!invoice_valid"
            >
            Hiányzó adatok: Számlázási adatok!
            </v-alert>
            <v-alert
              border="top"
              colored-border
              type="error"
              elevation="2"
              v-if="empty_items"
            >
            Hiányzó adatok: Üres számla (nincsenek tételek)!
            </v-alert>
          </v-container>
          <v-progress-linear
            v-if="loader"
            indeterminate
            color="red"
          ></v-progress-linear>
        <v-container>
        <div v-html="invoice_preview"></div>
        <v-row>
          <v-col cols="12" md="4">
          <v-btn v-if="invoice_preview && !invoice_id" rounded color="primary" dark @click="finalize = true" :loading="loader">Véglegesít</v-btn>
          <v-btn v-if="invoice_id && client_valid && invoice_valid" :loading="button_loader" :disabled="button_loader" rounded color="primary" @click="downloadInvoice()">Letöltés</v-btn>
          </v-col>
          <v-col cols="12" md="4">
          <v-btn v-if="invoice_id && client_valid && invoice_valid" :loading="button_loader" :disabled="button_loader" rounded color="primary" @click="getInvoice()">Nyomtatás</v-btn>
          </v-col>
        </v-row>
        <v-row v-if="invoice_id && client_valid && invoice_valid">
          <v-col cols="12" md="5">
            <v-text-field
              v-model="full_name"
              outlined
              label="Címzett"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="5">
            <v-text-field
              v-model="email"
              outlined
              label="E-mail cím"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2" class="mt-2">
          <v-btn v-if="invoice_id && client_valid && invoice_valid" :loading="button_loader" :disabled="button_loader" rounded color="primary" @click="sendInvoice()">Küldés</v-btn>
          </v-col>
        </v-row>
        </v-container>
      </div>
    <v-snackbar
       v-model="create_dialog"
     >
       {{ create_message }}
       {{ create_error }}
       <v-btn
          color="red"
          text
          @click="create_dialog = false"
        >
          Bezár
        </v-btn>
     </v-snackbar>
  <div class="mt-12"></div>
  <br>
  </div>
</template>

<script>
import Clients from '../components/Clients.vue'
import InvoiceDetails from '../components/InvoiceDetails.vue'
import {mapFields} from 'vuex-map-fields'

  export default {
    components: {
      Clients,
      InvoiceDetails,
    },
    data () {
      return {
        button_loader: false,
        full_name: '',
        email: '',
      }
    },
    methods: {
      getInvoice: async function() {
        this.button_loader = true
        await this.gettoken()
        this.$http.post('billingo_services/download_invoice', {
          token: this.token,
          school_id: this.school_id,
          invoice_id: this.invoice_id,
        }, {responseType: 'blob'}).then((response) => {
          this.button_loader = false
          //Create a Blob from the PDF Stream
          const file = new Blob(
          [response.data],
          {type: 'application/pdf'});
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          //Open the URL on new Window
          window.open(fileURL);
        }, (error) => {
          this.error_text = error.data
        })
      },
      downloadInvoice: async function() {
        this.preloader = true
        this.button_loader = true
        await this.gettoken()
        this.$http.post('billingo_services/download_invoice', {
          token: this.token,
          school_id: this.school_id,
          invoice_id: this.invoice_id,
        }, {responseType: 'blob'}).then((response) => {
          this.preloader = false
          this.button_loader = false
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          var szamlaszam = this.invoice_number.replace("/", "-");
          link.setAttribute('download', szamlaszam+'.pdf');
          document.body.appendChild(link);
          link.click();
        }, (error) => {
          this.error_text = error.data
        })
      },
      sendInvoice: async function() {
        this.button_loader = true
        await this.gettoken()
        var szamlaszam = this.invoice_number.replace("/", "-");
        this.$http.post('billingo_services/send_invoice', {
          token: this.token,
          school_id: this.school_id,
          invoice_id: this.invoice_id,
          full_iv_number: szamlaszam,
          email: this.email,
          name: this.full_name
        }).then((response) => {
          this.button_loader = false
          console.log(response.data);
          if (response.data['success'] == true) {
            this.create_message = response.data['message'];
          } else {
            this.create_error = response.data['message'];
          }
          this.create_dialog = true
        }, (error) => {
          console.log(error.data);
          this.button_loader = false
        })
      },
      getStudentDetails: async function() {
        await this.gettoken()
        this.$http.post('services/diakok_adatok', {
          token: this.token,
          customer_id: this.customerId,
          contract_id: this.contractId,
        }).then((response) => {
            this.full_name = response.data['tanulo'][0]['full_name'];
            this.email = response.data['tanulo'][0]['email1'];
        }, (error) => {
          this.error_text = error.data
        })
      },
    },
    mounted() {
    },
    watch: {
      invoice_tab(value) {
          if (value != 2) {
            this.invoice_preview = null
          }
      },
      invoice_id(value) {
          if (value) {
            this.getStudentDetails();
            //this.download_dialog = true
          }
      },
    },
    computed: {
    ...mapFields([
      'functions.invoice_tab',
      'functions.loader',
      'functions.invoice_preview',
      'functions.finalize',
      'functions.client_valid',
      'functions.invoice_valid',
      'functions.empty_items',
      'ids.billingo_id',
      'ids.invoice_id',
      'ids.mod_invoice_id',
      'ids.invoice_number',
      'ids.school_id',
      'ids.customerId',
      'ids.contractId',
      'functions.create_message',
      'functions.create_error',
      'functions.create_dialog',
  ]),
  },
  }
</script>

<style lang="css">
</style>
