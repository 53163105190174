<template lang="html">
  <div class="component-wrapper">
        <v-container>
          <v-expansion-panels v-model="panel" :disabled="!customerId">
          <v-expansion-panel>
          <v-expansion-panel-header class="subtitle-1"><b>Tanuló adatok</b></v-expansion-panel-header>
          <v-expansion-panel-content>
          <v-divider></v-divider><br>
          <v-card flat>
          <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <span>Név</span>
              <v-text-field
                :value="full_name"
                @change="full_name = $event"
                outlined
                flat
                solo
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <span>Ország</span>
              <v-text-field
                :value="per_country"
                @change="per_country = $event"
                outlined
                flat
                solo
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <span>Irányítószám</span>
              <v-text-field
                :value="per_zip"
                @change="per_zip = $event"
                outlined
                flat
                solo
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <span>Város</span>
              <v-text-field
                :value="per_city"
                @change="per_city = $event"
                outlined
                flat
                solo
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <span>Közterület</span>
              <v-text-field
                :value="per_public_premises"
                @change="per_public_premises = $event"
                outlined
                flat
                solo
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <span>Közterület jellege</span>
              <v-text-field
                :value="per_type_of_public_premises"
                @change="per_type_of_public_premises = $event"
                outlined
                flat
                solo
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
             <span>Házszám</span>
              <v-text-field
                :value="per_house_number"
                @change="per_house_number = $event"
                outlined
                flat
                solo
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <span>Épület</span>
              <v-text-field
                :value="per_building_number"
                @change="per_building_number = $event"
                outlined
                flat
                solo
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <span>Lépcsőház</span>
              <v-text-field
                :value="per_stairway_number"
                @change="per_stairway_number = $event"
                outlined
                flat
                solo
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <span>Emelet</span>
              <v-text-field
                :value="per_floor_number"
                @change="per_floor_number = $event"
                outlined
                flat
                solo
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <span>Ajtó</span>
              <v-text-field
                :value="per_door_number"
                @change="per_door_number = $event"
                outlined
                flat
                solo
              ></v-text-field>
            </v-col>
          </v-row>
          </v-card-text>
          </v-card>
          </v-expansion-panel-content>
          </v-expansion-panel>
          </v-expansion-panels>
          <br>
          <v-card>
          <v-card-text>
          <p class="subtitle-1 black--text"><b>Számlázási adatok</b></p>
          <v-divider></v-divider><br>
          <v-row>
          <v-col cols="12">
            <span>Rögzített partnerek</span>
            <v-autocomplete
              v-model="billingo_id"
              :items="client_list"
              item-text="name"
              item-value="billingo_id"
              label="Partner választása"
              outlined
              flat
              solo
            ></v-autocomplete>
          </v-col>
          </v-row>
          <v-row>
            <v-col md="4">
            <v-btn rounded color="primary" dark @click="copyAddress()">Másolás (tanuló adatok)</v-btn>
            </v-col>
            <v-col md="4">
            <v-btn rounded color="primary" dark @click="create_validate()">Rögzítés új partnerként</v-btn>
            </v-col>
          </v-row>
          <br>
          <v-container>
            <v-form
              ref="clients_form"
              v-model="client_valid"
              lazy-validation
            >
          <v-row>
            <v-col cols="12" md="6">
              <span>Név</span>
              <v-text-field
                :value="name"
                @change="name = $event"
                outlined
                flat
                solo
                :rules="[rules.required]"
              ></v-text-field>
              <v-btn :loading="search_company_loader" :disabled="search_company_loader" rounded color="primary" @click="searchConpany()">Cégkeresés
                <v-icon right dark>search</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" md="6">
              <span>Adószám</span>
              <v-text-field
                :value="taxcode"
                @change="taxcode = $event"
                outlined
                flat
                solo
                :rules="tax_code_rules"
                :disabled="taxCodeDisabled"
                v-mask="'########-#-##'"
                masked="false"
              >
            </v-text-field>
            <v-checkbox
            v-model="no_company"
            label="Magánszemély"
            dense
            ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <span>Ország</span>
              <v-autocomplete
                :value="country"
                @change="country = $event"
                :items="country_list"
                item-text="country"
                item-value="country"
                label="Ország választása"
                :rules="[rules.required]"
                outlined
                flat
                solo
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4">
              <span>Irányítószám</span>
              <v-text-field
                :value="postcode"
                @change="getPerCityName($event), postcode = $event"
                v-mask="'####'"
                outlined
                flat
                solo
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <span>Város</span>
              <v-text-field
                :value="city"
                @change="city = $event"
                :loading = "per_city_load"
                outlined
                flat
                solo
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <span>Közterület neve</span>
              <v-text-field
                :value="street_name"
                @change="street_name = $event"
                outlined
                flat
                solo
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <span>Közterület jellege</span>
              <v-text-field
                :value="street_type"
                @change="street_type = $event"
                outlined
                flat
                solo
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <span>Házszám</span>
              <v-text-field
                :value="house_nr"
                @change="house_nr = $event"
                outlined
                flat
                solo
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <span>Épület</span>
              <v-text-field
                :value="block"
                @change="block = $event"
                outlined
                flat
                solo
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <span>Lépcsőház</span>
              <v-text-field
                :value="entrance"
                @change="entrance = $event"
                outlined
                flat
                solo
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <span>Emelet</span>
              <v-text-field
                :value="floor"
                @change="floor = $event"
                outlined
                flat
                solo
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <span>Ajtó</span>
              <v-text-field
                :value="door"
                @change="door = $event"
                outlined
                flat
                solo
              ></v-text-field>
            </v-col>
          </v-row>
          </v-form>
          </v-container>
          </v-card-text>
          </v-card>
          <br>
          <v-row>
            <v-btn rounded color="primary" dark @click="nextTab()">Tovább</v-btn>
          </v-row>
        </v-container>

        <v-row justify="center">
          <v-dialog v-model="company_dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
              <v-toolbar dark color="primary">
                <v-toolbar-title>Cégek listája</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn dark text @click="company_dialog = false"><v-icon>mdi-close</v-icon></v-btn>
                </v-toolbar-items>
              </v-toolbar>
              <v-data-table
                :headers="headers"
                :items="company_list"
                :items-per-page="5"
                class="elevation-1"
                @click:row="searchConpanyDetails"
              ></v-data-table>
            </v-card>
          </v-dialog>
        </v-row>

  </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import { EventBus } from '../event-bus'

  export default {
    data () {
      return {
        client_id: null,
        full_name: '',
        taxcode: '',
        per_country: '',
        per_zip: '',
        per_city: '',
        per_public_premises: '',
        per_type_of_public_premises: '',
        per_house_number: '',
        per_building_number: '',
        per_stairway_number: '',
        per_floor_number: '',
        per_door_number: '',
        billingo_id_01: null,
        billingo_id_02: null,
        name: '',
        street_name: '',
        street_type: '',
        house_nr: '',
        city: '',
        postcode: '',
        country: 'Magyarország',
        block: '',
        entrance: '',
        floor: '',
        door: '',
        client_list: [],
        country_list: [],
        rules: {
            required: value => !!value || 'Kötelező mező!',
            tax_code: value => (this.name == this.full_name || this.no_company == true || !!value) || 'Céges vevő esetén kötelező!',
          },
        tax_code_rules: [
          value => (this.name == this.full_name || this.no_company == true || !!value) || 'Céges vevő esetén kötelező!',
          value => (this.name == this.full_name || this.no_company == true || /\d\d\d\d\d\d\d\d-\d-\d\d/.test(value)) || 'Hibás adószám formátum!',
        ],
        per_city_load: false,
        no_company: false,
        company_list: [],
        company_dialog: false,
        headers: [
           { text: 'Cégnév', value: 'name' },
         ],
        search_company_loader: false,
      }
    },
    methods: {
      getStudentDetails: async function() {
        await this.gettoken()
        this.$http.post('services/diakok_adatok', {
          token: this.token,
          customer_id: this.customerId,
          contract_id: this.contractId,
        }).then((response) => {
            this.billingo_id_01 = response.data['tanulo'][0]['billingo_id_01']
            this.billingo_id_02 = response.data['tanulo'][0]['billingo_id_02']
            this.school_id = response.data['school_id']
            this.client_id = response.data;
            this.full_name = response.data['tanulo'][0]['full_name']
            this.per_country = response.data['tanulo'][1]['country']
            this.per_zip = response.data['tanulo'][1]['zip']
            this.per_city = response.data['tanulo'][1]['city']
            this.per_public_premises = response.data['tanulo'][1]['public_premises']
            this.per_type_of_public_premises = response.data['tanulo'][1]['type_of_public_premises']
            this.per_house_number = response.data['tanulo'][1]['house_number']
            this.per_building_number = response.data['tanulo'][1]['building_number']
            this.per_stairway_number = response.data['tanulo'][1]['stairway_number']
            this.per_floor_number = response.data['tanulo'][1]['floor_number']
            this.per_door_number = response.data['tanulo'][1]['door_number']
            this.billingoIdSet()
        }, (error) => {
          this.error_text = error.data
        })
      },
      getClientDetails: async function() {
        await this.gettoken()
        this.$http.post('billingo_services/getclient_20210621', {
          token: this.token,
          school_id: this.school_id,
          customer_id: this.customerId,
          billingo_id: this.billingo_id,
        }).then((response) => {
          this.name = response.data[0]['attributes']['name']
          this.taxcode = response.data[0]['attributes']['taxcode']
          this.street_name = response.data[0]['attributes']['billing_address']['street_name']
          this.street_type = response.data[0]['attributes']['billing_address']['street_type']
          this.house_nr = response.data[0]['attributes']['billing_address']['house_nr']
          this.city = response.data[0]['attributes']['billing_address']['city']
          this.postcode = response.data[0]['attributes']['billing_address']['postcode']
          this.country = response.data[0]['attributes']['billing_address']['country']
          this.block = response.data[0]['attributes']['billing_address']['block']
          this.entrance = response.data[0]['attributes']['billing_address']['entrance']
          this.floor = response.data[0]['attributes']['billing_address']['floor']
          this.door = response.data[0]['attributes']['billing_address']['door']
        }, (error) => {
          this.error_text = error.data
        })
      },
      getBillingoIds: async function() {
        await this.gettoken()
        this.$http.post('services/diakok_adatok', {
          token: this.token,
          customer_id: this.customerId,
          contract_id: this.contractId,
        }).then((response) => {
          this.billingo_id_01 = response.data['tanulo'][0]['billingo_id_01']
          this.billingo_id_02 = response.data['tanulo'][0]['billingo_id_02']
        }, (error) => {
          this.error_text = error.data
        })
      },
      searchConpany: async function() {
        this.search_company_loader = true
        await this.gettoken()
        this.$http.post('services/cegkereso', {
          token: this.token,
          cegnev: this.name
        }).then((response) => {
          this.search_company_loader = false
          this.company_list = response.data;
          this.company_dialog = true
        }, (error) => {
          this.error_text = error.data
        })
      },
      searchConpanyDetails: async function(value) {
        await this.gettoken()
        this.$http.post('services/cegkereso_details_230711', {
          token: this.token,
          url: value.url
        }).then((response) => {
          this.company_dialog = false
          this.company_details = response.data;
          this.name = response.data['cegnev']
          this.taxcode = response.data['adoszam']
          this.postcode = response.data['irsz']
          this.city = response.data['city']
          this.street_name = response.data['cim']
          this.country = 'Magyarország'
        }, (error) => {
          this.error_text = error.data
        })
      },
      getClients: async function() {
        await this.gettoken()
        this.$http.post('billingo_services/get_billingo_clients', {
          token: this.token,
          school_id: this.school_id,
        }).then((response) => {
          this.client_list = response.data
        }, (error) => {
          this.error_text = error.data
        })
      },
      getSavedClients: async function() {
        await this.gettoken()
        this.$http.post('services/clients_list', {
          token: this.token,
          school_id: this.school_id,
        }).then((response) => {
          this.client_list = response.data
        }, (error) => {
          this.error_text = error.data
        })
      },
      copyAddress() {
        this.name = this.full_name
        this.street_name = this.per_public_premises
        this.street_type = this.per_type_of_public_premises
        this.house_nr = this.per_house_number
        this.city = this.per_city
        this.postcode = this.per_zip
        this.country = this.per_country
        this.block = this.per_building_number
        this.entrance = this.per_stairway_number
        this.floor = this.per_floor_number
        this.door = this.per_door_number
      },
      nextTab: async function() {
        await this.validate()
        if (this.client_valid) {
          this.invoice_tab = 1
          this.scrollToTop()
        }
      },
      createClient: async function() {
        await this.gettoken()
        this.$http.post('billingo_services/create_client', {
          token: this.token,
          school_id: this.school_id,
          customer_id: this.customerId,
          billingo_id: this.billingo_id,
          name: this.name,
          street_name: this.street_name,
          street_type: this.street_type,
          house_nr: this.house_nr,
          city: this.city,
          postcode: this.postcode,
          country: this.country,
          block: this.block,
          entrance: this.entrance,
          floor: this.floor,
          door: this.door,
          taxcode: this.taxcode
        }).then((response) => {
          this.billingo_id = response.data[0]['id']
          this.await = 'ready'
          this.getBillingoIds();
        }, (error) => {
          this.error_text = error.data
        })
      },
      createPartner: async function() {
        await this.gettoken()
        this.$http.post('billingo_services/create_partner', {
          token: this.token,
          school_id: this.school_id,
          customer_id: this.customerId,
          billingo_id: this.billingo_id,
          name: this.name,
          street_name: this.street_name,
          street_type: this.street_type,
          house_nr: this.house_nr,
          city: this.city,
          postcode: this.postcode,
          country: this.country,
          block: this.block,
          entrance: this.entrance,
          floor: this.floor,
          door: this.door,
          taxcode: this.taxcode
        }).then((response) => {
          this.billingo_id = response.data
          this.getSavedClients();
          this.getClientDetails()
        }, (error) => {
          this.error_text = error.data
        })
      },
      validate () {
        this.$refs.clients_form.validate()
      },
      create_validate() {
        if (this.$refs.clients_form.validate()) {
          this.createPartner()
        }
      },
      reset () {
        this.$refs.clients_form.reset()
      },
      resetValidation () {
        this.$refs.clients_form.resetValidation()
      },
      scrollToTop() {
       window.scrollTo(0,0);
      },
      getCountryList: async function() {
        await this.gettoken()
        this.$http.post('/services/country_list', {
          token: this.token,
        }).then((response) => {
          this.country_list = response.data
        }, (error) => {
          console.log(error.data);
        })
      },
      getPerCityName: async function() {
        this.city = ''
        this.per_city_load = true
        await this.gettoken()
        this.$http.post('/services/irsz_list', {
          token: this.token,
          irsz: this.postcode
        }).then((response) => {
          this.per_city_load = false
          this.city = response.data[0]['place']
        }, (error) => {
          console.log(error.data);
        })
      },
      billingoIdSet() {
        this.billingo_id = null
        if (this.school_id == 1) {
          if (this.billingo_id_01) {
            this.billingo_id = this.billingo_id_01
          }
        }
        if (this.school_id == 2) {
          if (this.billingo_id_02) {
            this.billingo_id = this.billingo_id_02
          }
        }
      },
      billingoIdClear() {
        this.billingo_id_01 = null
        this.billingo_id_02 = null
      }
    },
    mounted() {
      this.getStudentDetails();
      this.getSavedClients();
      this.getCountryList()
      if (!this.customerId) {
        this.no_company = true;
      }
    },
    watch: {
      invoice_tab: async function(value) {
        await this.validate()
            if (value == 2) {
                this.await = null
                if (this.client_valid) {
                  this.createClient()
                }
              }
      },
      school_id() {
        this.getSavedClients();
        this.billingoIdSet()
      },
      billingo_id() {
        this.getClientDetails()
      },
      name(value) {
        if (value == '') {
          this.resetValidation()
        }
        if (value == this.full_name && this.full_name != "") {
          this.taxcode = null
        }
      },
    },
    created() {
      EventBus.$on('clearBillingoIds', () => {
            this.billingoIdClear();
      })
    },
    computed: {
    ...mapFields([
      'ids.customerId',
      'ids.contractId',
      'ids.school_id',
      'ids.billingo_id',
      'ids.mod_invoice_id',
      'functions.invoice_tab',
      'functions.await',
      'functions.client_valid',
      'functions.panel',
  ]),
    taxCodeDisabled() {
      if (this.name == this.full_name && this.full_name != "") {
        return true;
      } else {
        return false;
      }
    }
  },
  }
</script>

<style lang="css">
tr {
    cursor: pointer;
}
</style>
