<template lang="html">
<div>
  <div class="d-none d-print-block">
    <p class="text-center title">{{username}}</p>
    <p class="text-center title">({{dates[0]}} - {{dates[1]}})</p>
    <p class="text-center title">Összesen: {{vizsgadij_szum}} Ft</p>
    <p class="text-center">Utolsó adatlekérés időpontja: {{get_data_time}}</p>
  </div>
  <v-card class="d-print-none">
    <v-card-title>
      Vizsgadíjak<div class="d-print-none">: {{dates[0]}} - {{dates[1]}}</div>
      <v-spacer></v-spacer>
      <v-radio-group v-model="szukitett" row class="d-print-none">
        <v-radio label="Szűkített nézet" :value='true'></v-radio>
        <v-radio label="Részletes nézet" :value='false'></v-radio>
      </v-radio-group>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Keresés"
        single-line
        hide-details
        class="d-print-none"
      ></v-text-field>
    </v-card-title>
  <v-data-table
     :headers="headers"
     :hide-default-header="!szukitett"
     :items="vizsgadij"
     :items-per-page="15"
     :search="search"
     :sort-by="['invoice_date']"
     class="d-print-table"
     :loading="table_loader"
     loading-text="Betöltés..."
   >
   <template v-if="!szukitett" v-slot:header="{ props: { headers } }">
        <thead>
          <tr>
            <th>Név</th>
            <th>Számlázási név</th>
            <th>Születési idő</th>
            <th>Szerződés</th>
            <th>Szerződéskötés helye</th>
            <th>Összeg</th>
            <th>Tárgy</th>
            <th>Fizetési mód</th>
            <th>Státusz</th>
            <th>Számlaszám</th>
            <th>Befizetés ideje</th>
            <th>Kötelezettségek</th>
            <th>Megjegyzések</th>
          </tr>
        </thead>
   </template>
   <template v-if="!szukitett" v-slot:body="{ items }">
     <tbody>
        <tr v-for="item in items" :key="item.payment_id">
          <td>{{ item.full_name }}</td>
          <td>{{ item.billing_name }}</td>
          <td>{{ item.bd }}</td>
          <td>{{ item.contract_name }}</td>
          <td>{{ item.place_name }}</td>
          <td>{{ item.amount }}</td>
          <td>{{ item.item_name }}</td>
          <td>{{ item.payment_type }}</td>
          <td>{{ item.status }}</td>
          <td>{{ item.invoice_number }}</td>
          <td>{{ item.invoice_date }}</td>
          <td>{{ item.ob_name }}</td>
          <td>{{ item.ob_desc }}</td>
        </tr>
      </tbody>
   </template>
  </v-data-table>
  </v-card>

  <table border="1" class="d-none d-print-block" style="width:100%">
    <thead>
      <tr>
        <th>Név</th>
        <th>Születési idő</th>
        <th>Szerződés</th>
        <th>Szerződéskötés helye</th>
        <th>Összeg</th>
        <th>Tárgy</th>
        <th>Fizetési mód</th>
        <th>Befizetés ideje</th>
        <th>Kötelezettségek</th>
        <th>Megjegyzések</th>
      </tr>
    </thead>
    <tbody>
       <tr v-for="item in vizsgadij" :key="item.payment_id">
         <td>{{ item.full_name }}</td>
         <td>{{ item.bd }}</td>
         <td>{{ item.contract_name }}</td>
         <td>{{ item.place_name }}</td>
         <td>{{ item.amount }}</td>
         <td>{{ item.item_name }}</td>
         <td>{{ item.payment_type }}</td>
         <td>{{ item.invoice_date }}</td>
         <td>{{ item.ob_name }}</td>
         <td>{{ item.ob_desc }}</td>
       </tr>
     </tbody>
  </table>

  <br>
  <v-container class="title text-center d-print-none">
    Összesen: {{vizsgadij_szum}} Ft
  </v-container>
  <br>
  <v-container class="d-print-none">
  <v-card v-if="fbuser != 'suli@csigajogsi.hu'">
    <v-card-title>
      Egyéb tételek
      <v-spacer></v-spacer>
    </v-card-title>
  <v-data-table
     :headers="vd_exp_headers"
     :items="vd_expenses"
     :items-per-page="15"
     class="d-print-table"
     :loading="table_loader"
     loading-text="Betöltés..."
   >
    <template v-slot:item.targy="props">
      <v-edit-dialog
        :return-value.sync="props.item.targy"
        @save="modVdExpenses(props.item)"
        save-text="Mentés"
        cancel-text="Mégsem"
        large
        persistent
      > {{ props.item.targy }}
        <template v-slot:input>
          <v-text-field
            v-model="props.item.targy"
            label="Megnevezés szerkesztése"
            single-line
            :disabled="props.item.regdatum != most && props.item.regdatum != yesterday && admin != 1"
          ></v-text-field>
        </template>
      </v-edit-dialog>
    </template>
    <template v-slot:item.amount="props">
      <v-edit-dialog
        :return-value.sync="props.item.amount"
        @save="modVdExpenses(props.item)"
        save-text="Mentés"
        cancel-text="Mégsem"
        large
        persistent
      > {{ props.item.amount }}
        <template v-slot:input>
          <v-text-field
            v-model="props.item.amount"
            label="Összeg szerkesztése"
            single-line
            type="number"
            :disabled="props.item.regdatum != most && props.item.regdatum != yesterday && admin != 1"
          ></v-text-field>
        </template>
      </v-edit-dialog>
    </template>
    <template v-slot:item.regdatum="props">
      <v-edit-dialog
        :return-value.sync="props.item.regdatum"
        @save="modVdExpenses(props.item)"
        save-text="Mentés"
        cancel-text="Mégsem"
        large
        persistent
      > {{ props.item.regdatum }}
        <template v-slot:input>
          <v-text-field
            v-model="props.item.regdatum"
            label="Dátum szerkesztése"
            single-line
            :disabled="props.item.regdatum != most && props.item.regdatum != yesterday && admin != 1"
          ></v-text-field>
        </template>
      </v-edit-dialog>
    </template>
    <template v-slot:item.orvosi="props">
      <v-checkbox v-model="props.item.orvosi" value="Orvosi" @change="modVdExpenses(props.item)" :disabled="props.item.regdatum != most && props.item.regdatum != yesterday && admin != 1"></v-checkbox>
    </template>
    <template v-slot:item.jogsi="props">
      <v-checkbox v-model="props.item.jogsi" value="Jogosítvány" @change="modVdExpenses(props.item)" :disabled="props.item.regdatum != most && props.item.regdatum != yesterday && admin != 1"></v-checkbox>
    </template>
    <template v-slot:item.bizi="props">
      <v-checkbox v-model="props.item.bizi" value="Bizonyítvány" @change="modVdExpenses(props.item)" :disabled="props.item.regdatum != most && props.item.regdatum != yesterday && admin != 1"></v-checkbox>
    </template>
    <template v-slot:item.forditas="props">
      <v-checkbox v-model="props.item.forditas" value="Fordítás" @change="modVdExpenses(props.item)" :disabled="props.item.regdatum != most && props.item.regdatum != yesterday && admin != 1"></v-checkbox>
    </template>
    <template v-slot:item.delete="props">
      <v-btn class="ma-2 d-print-none" outlined fab small color="red" @click="delete_dialog = true, expenses_del_id = props.item.id, expenses_del_subject = props.item.targy" :disabled="props.item.regdatum != most && props.item.regdatum != yesterday && admin != 1">
        <v-icon>delete</v-icon>
      </v-btn>
    </template>
  </v-data-table>
  <v-container class="d-print-none">
    <v-form
    ref="expenses_form"
    v-model="expenses_valid"
    lazy-validation
    >
  <v-row>
    <v-col cols="12" md="4">
      <v-text-field
        :value="subject"
        @change="subject = $event"
        label="Megnevezés"
        outlined
        :rules="[rules.required]"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field
        v-model="amount"
        label="Összeg"
        outlined
        type="number"
        :rules="[rules.required]"
      ></v-text-field>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="6" md="3"><v-checkbox v-model="orvosi" label="Orvosi" value="Orvosi"></v-checkbox></v-col>
    <v-col cols="6" md="3"><v-checkbox v-model="jogsi" label="Jogosítvány" value="Jogosítvány"></v-checkbox></v-col>
    <v-col cols="6" md="3"><v-checkbox v-model="bizi" label="Bizonyítvány" value="Bizonyítvány"></v-checkbox></v-col>
    <v-col cols="6" md="3"><v-checkbox v-model="forditas" label="Fordítás" value="Fordítás"></v-checkbox></v-col>
  </v-row>
  <v-row>
    <v-col cols="12" md="4">
      <v-btn
        color="primary"
        class="mt-4"
        @click="validate()"
      >
        Hozzáad
        <v-icon right dark>add_circle_outline</v-icon>
      </v-btn>
    </v-col>
  </v-row>
  </v-form>
  </v-container>
  </v-card>
  </v-container>

  <div class="d-none d-print-block">
    <center>
    <h2>Egyéb tételek</h2>
    </center>
    <br>
  <table border="1" style="width:100%">
    <thead>
      <tr>
        <th>Dátum</th>
        <th>Megnevezés</th>
        <th>Összeg</th>
        <th>Orvosi</th>
        <th>Jogosítvány</th>
        <th>Bizonyítvány</th>
        <th>Fordítás</th>
      </tr>
    </thead>
    <tbody>
       <tr v-for="item in vd_expenses" :key="item.id">
         <td>{{ item.regdatum }}</td>
         <td>{{ item.targy }}</td>
         <td>{{ item.amount }}</td>
         <td>{{ item.orvosi }}</td>
         <td>{{ item.jogsi }}</td>
         <td>{{ item.bizi }}</td>
         <td>{{ item.forditas }}</td>
       </tr>
     </tbody>
  </table>
  </div>

  <v-dialog v-model="delete_dialog" persistent max-width="290">
     <v-card>
       <v-card-title class="headline">Törlés</v-card-title>
       <v-card-text>Biztosan törli a tételt? ({{expenses_del_subject}})</v-card-text>
       <v-card-actions>
         <v-spacer></v-spacer>
         <v-btn color="green darken-1" text @click="delVdExpenses(), delete_dialog = false">Igen</v-btn>
         <v-btn color="green darken-1" text @click="delete_dialog = false">Nem</v-btn>
       </v-card-actions>
     </v-card>
   </v-dialog>
   <v-snackbar
      v-model="response_dialog"
    >
      {{ response }}
      <v-btn
         color="red"
         text
         @click="response_dialog = false"
       >
         Bezár
       </v-btn>
    </v-snackbar>
</div>
</template>

<script>
import {mapFields} from 'vuex-map-fields';
import { EventBus } from '../event-bus';

  export default {
    data () {
      return {
        headers: [
          { text: 'Tanuló név', value: 'full_name' },
          { text: 'Számlázási név', value: 'billing_name' },
          { text: 'Születési idő', value: 'bd' },
          { text: 'Szerződés', value: 'contract_name' },
          { text: 'Összeg', value: 'amount' },
          { text: 'Fizetési mód', value: 'payment_type' },
          { text: 'Befizetés ideje', value: 'invoice_date' },
          { text: 'Közelezettségek', value: 'ob_name' },
          { text: 'Megjegyzések', value: 'ob_desc' },
        ],
        vd_exp_headers: [
          { text: 'Dátum', value: 'regdatum' },
          { text: 'Megnevezés', value: 'targy' },
          { text: 'Összeg', value: 'amount' },
          { text: 'Orvosi', value: 'orvosi' },
          { text: 'Jogosítvány', value: 'jogsi' },
          { text: 'Bizonyítvány', value: 'bizi' },
          { text: 'Fordítás', value: 'forditas' },
          { text: 'Műveletek', value: 'delete' },
        ],
        vizsgadij: [],
        vizsgadij_szum: '',
        szukitett: true,
        search: '',
        table_loader: false,
        vd_expenses: [],
        today: new Date().toISOString().substr(0, 10),
        most: new Date().toISOString().substr(0, 10),
        yesterday: new Date(Date.now() - 1 * 86400000 - new Date().getTimezoneOffset() * 60000).toISOString().split('T')[0],
        amount: '',
        subject: '',
        orvosi: '',
        jogsi: '',
        bizi: '',
        forditas: '',
        response: '',
        response_dialog: false,
        expenses_valid: true,
        rules: {
            required: value => !!value || 'Kötelező mező!',
          },
        expenses_del_id: '',
        expenses_del_subject: '',
        delete_dialog: false,
        get_data_time: '',
      }
    },
    methods: {
      getVizsgadij: async function() {
        this.table_loader = true
        await this.gettoken()
        this.$http.post('services/vizsgadijak', {
          token: this.token,
          user: this.iroda,
          dates: this.dates,
        }).then((response) => {
          this.table_loader = false
          this.vizsgadij = response.data
        }, (error) => {
          this.error_text = error.data
        })
      },
      sumVizsgadij: async function() {
        await this.gettoken()
        this.$http.post('services/szum_vizsgadijak', {
          token: this.token,
          user: this.iroda,
          iroda: this.iroda,
          dates: this.dates,
        }).then((response) => {
          this.vizsgadij_szum = response.data
        }, (error) => {
          this.error_text = error.data
        })
      },
      getVdExpenses: async function() {
        await this.gettoken()
        this.$http.post('services/get_vizsgadijak', {
          token: this.token,
          user: this.iroda,
          dates: this.dates,
        }).then((response) => {
          this.vd_expenses = response.data
        }, (error) => {
          this.error_text = error.data
        })
      },
      saveVdExpenses: async function() {
        await this.gettoken()
        this.$http.post('services/add_vd_expenses', {
          token: this.token,
          user: this.iroda,
          regdatum: this.today,
          amount: this.amount,
          subject: this.subject,
          orvosi: this.orvosi,
          jogsi: this.jogsi,
          bizi: this.bizi,
          forditas: this.forditas,
        }).then((response) => {
          this.response_dialog = true
          this.response = response.data
          this.getVdExpenses()
          this.subject = null
          this.amount = null
          this.orvosi = null
          this.jogsi = null
          this.bizi = null
          this.forditas = null
          this.resetValidation()
          this.sumVizsgadij()
        }, (error) => {
          this.error_text = error.data
        })
      },
      modVdExpenses: async function(value) {
        await this.gettoken()
        this.$http.post('services/update_vd_expenses', {
          token: this.token,
          regdatum: value.regdatum,
          amount: value.amount,
          subject: value.targy,
          id: value.id,
          orvosi: value.orvosi,
          jogsi: value.jogsi,
          bizi: value.bizi,
          forditas: value.forditas,
        }).then((response) => {
          this.response_dialog = true
          this.response = response.data
          this.getVdExpenses()
          this.sumVizsgadij()
        }, (error) => {
          this.error_text = error.data
        })
      },
      delVdExpenses: async function() {
        await this.gettoken()
        this.$http.post('services/delete_vd_expenses', {
          token: this.token,
          id: this.expenses_del_id
        }).then((response) => {
          this.response_dialog = true
          this.response = response.data
          this.getVdExpenses()
          this.sumVizsgadij()
        }, (error) => {
          this.error_text = error.data
        })
      },
      refresh() {
        this.getVizsgadij()
        this.sumVizsgadij()
        this.getVdExpenses()
        this.get_data_time = new Date().toLocaleString("hu-HU", {timeZone: "Europe/Budapest"})
      },
      validate () {
        if (this.$refs.expenses_form.validate()) {
          this.saveVdExpenses()
        }
      },
      reset () {
        this.$refs.expenses_form.reset()
      },
      resetValidation () {
        this.$refs.expenses_form.resetValidation()
      },
    },
    mounted() {
      this.refresh()
    },
    watch: {
      expenses_select(value) {
          if (value == 'vd') {
            this.refresh()
          }
      },
    },
    created() {
      EventBus.$on('vdRefresh', () => {
            this.refresh();
      })
    },
    computed: {
    ...mapFields([
      'ids.fbuser',
      'ids.username',
      'ids.user_place_id',
      'ids.iroda',
      'ids.admin',
      'functions.dates',
      'functions.expenses_select',
    ]),
    },
  }
</script>

<style lang="css">
</style>
