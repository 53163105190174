import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify);

// Translation provided by Vuetify (javascript)
import hu from 'vuetify/es5/locale/hu'

export default new Vuetify({
lang: {
  locales: { hu, },
  current: 'hu',
},
theme: {
   themes: {
     light: {
       primary: '#3f51b5',
     },
   },
 }
});
