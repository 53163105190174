<template>
  <v-container>
    <v-card max-width="344" class="mx-auto">
      <v-card-title class="justify-center" v-if="!fbuser">
        Bejelentkezés
      </v-card-title>
      <v-card-text class="text-center">
        <h3 v-if="fbuser">Bejelentkezve, mint: {{fbuser}}</h3>
        <div v-if="login_error">{{login_error}}</div>
        <v-row v-if="!fbuser" class="justify-center pt-3">
          <v-col cols="12">
            <v-text-field
              v-model="username"
              label="Felhasználónév"
              outlined
              @keyup.enter="Login()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="!fbuser" class="justify-center">
          <v-col cols="12">
            <v-text-field
              v-model="password"
              label="Jelszó"
              outlined
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              name="input-10-1"
              @click:append="show = !show"
              @keyup.enter="Login()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="!fbuser" class="justify-center pb-3">
            <v-btn @click="Login()" rounded color="primary">Küld</v-btn>
          </v-row>
          <v-row class="justify-center pt-3">
            <v-btn rounded color="primary" @click="logout" v-if="fbuser">Kilépés</v-btn>
          </v-row>

          <v-row class="justify-center pt-3">
            <v-btn to="/settings" outlined rounded color="primary" v-if="fbuser && admin">Beállítások</v-btn>
          </v-row>

      </v-card-text>
    </v-card>

  </v-container>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth';

import {
  mapFields
} from 'vuex-map-fields'
import router from './router'

export default {
  data () {
    return {
      username: '',
      password: '',
      login_error: '',
      show: false,
    }
  },
  methods: {
    Login: function () {
      firebase.auth().signInWithEmailAndPassword(this.username, this.password).then(function(user) {
        alert("Sikeres bejelentkezés!");
        var href = location.origin;
        var query_string = window.location.search;
        var urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('customerId')) {
        location.assign(href+query_string);
      } else if (urlParams.get('invoice_number')) {
        location.assign(href+"/szamlak"+query_string);
      } else {
        location.assign(href+"/szamlak");
      }
      }).catch(function(error) {
        alert(error)
      });
    },
    userState: function () {
      var user = firebase.auth().currentUser;
      if (user) {
        this.fbuser = user.email;
      } else {
        console.log("No user is signed in.");
        this.fbuser = null;
      }
    },
    logout: function () {
      firebase.auth().signOut().then(function() {
        alert("Sikeres kijelentkezés!");
      }).catch(function(error) {
        alert(error)
      });
    }
  },
  mounted() {
  this.userState()
  },
  computed: {
  ...mapFields([
    'ids.preselected_invoice',
    'ids.preselected_invoice_source',
    'ids.preselected_school_id',
    'ids.school_id',
    'ids.contractId',
    'ids.customerId',
    'ids.fbuser',
    'ids.admin',
]),
},
}
</script>

<style >

</style>
