<template lang="html">
<div>
 <v-container class="d-print-none">
  <div>
    <v-date-picker
     v-model="dates"
     full-width
     landscape
     range
     first-day-of-week="1"
   >
     <v-spacer></v-spacer>
     <v-btn text color="primary" @click="refreshAll()">Lekérdez</v-btn>
   </v-date-picker>
 </div>
  <v-row justify="center" class="d-print-none">
    <v-radio-group v-model="expenses_select" row>
      <v-radio label="Elszámolás" value="elszam"></v-radio>
      <v-radio label="Vizsgadíjak" value="vd"></v-radio>
    </v-radio-group>
  </v-row>
  </v-container>
  <Elszam v-show="expenses_select == 'elszam'"></Elszam>
  <Vizsgadij v-show="expenses_select == 'vd'"></Vizsgadij>
  <v-btn
    @click="print()"
    style="top: 120px;"
    fixed
    dark
    fab
    top
    color="white"
    right
    class="d-print-none"
  >
  <v-icon large color="black">print</v-icon>
  </v-btn>
</div>
</template>

<script>
import {mapFields} from 'vuex-map-fields';
import Elszam from './Elszam.vue'
import Vizsgadij from './Vizsgadij.vue'
import { EventBus } from '../event-bus';

  export default {
    components: {
      Vizsgadij,
      Elszam,
    },
    data () {
      return {
      }
    },
    methods: {
      sort() {
        this.dates.sort();
      },
      print() {
        window.print();
      },
      refresh_elszam() {
        EventBus.$emit('elszamRefresh')
      },
      refresh_vizsga() {
        EventBus.$emit('vdRefresh')
      },
      refreshAll() {
        this.sort()
        this.refresh_elszam()
        this.refresh_vizsga()
      }
    },
    mounted() {

    },
    watch: {

    },
    created() {
      EventBus.$on('elszamRefreshAll', () => {
            this.refreshAll();
      })
    },
    computed: {
    ...mapFields([
      'ids.fbuser',
      'ids.username',
      'ids.user_place_id',
      'functions.dates',
      'functions.expenses_select',
    ]),
    },
  }
</script>

<style lang="css">
</style>
