<template lang="html">
<div>
 <v-container>
  <v-card class="d-print-none">
  <v-card-title>Szűrési beállítások</v-card-title>
   <v-container>
     <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
    <v-card class="d-print-none" outlined>
    <v-expansion-panels
      flat
      multiple
    >

      <v-expansion-panel>
        <v-expansion-panel-header><b>Időszak 1.</b></v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="mt-2">
          <v-col cols="12" md="6">
            <v-menu ref="start_date_menu" v-model="start_date_menu" :close-on-content-click="false" :return-value.sync="start_date" transition="scale-transition" offset-y  min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field v-model="start_date" label="Kezdő dátum" append-icon="event" readonly v-on="on" outlined>
                </v-text-field>
              </template>
              <v-date-picker v-model="start_date" no-title scrollable first-day-of-week=1>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="start_date_menu = false">Mégsem</v-btn>
                <v-btn text color="primary" @click="$refs.start_date_menu.save(start_date)">OK</v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="6">
            <v-menu ref="end_date_menu" v-model="end_date_menu" :close-on-content-click="false" :return-value.sync="end_date" transition="scale-transition" offset-y  min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field :rules="dateRules" v-model="end_date" label="Befejező dátum" append-icon="event" readonly v-on="on" outlined>
                </v-text-field>
              </template>
              <v-date-picker v-model="end_date" no-title scrollable first-day-of-week=1>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="end_date_menu = false">Mégsem</v-btn>
                <v-btn text color="primary" @click="$refs.end_date_menu.save(end_date)">OK</v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          </v-row>
          <v-row>
            <v-date-picker
              v-model="stat_dates"
              full-width
              no-title        
              range
              first-day-of-week="1"
            >
              <v-spacer></v-spacer>
              <v-btn text color="red" @click="stat_dates=[]">Töröl</v-btn>
            </v-date-picker>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-divider></v-divider>
        <v-expansion-panel-header><b>Időszak 2.</b></v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="mt-2">
          <v-col cols="12" md="6">
            <v-menu ref="start_date_menu_2" v-model="start_date_menu_2" :close-on-content-click="false" :return-value.sync="start_date_2" transition="scale-transition" offset-y  min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field v-model="start_date_2" label="Kezdő dátum" append-icon="event" readonly v-on="on" outlined>
                </v-text-field>
              </template>
              <v-date-picker v-model="start_date_2" no-title scrollable first-day-of-week=1>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="start_date_menu_2 = false">Mégsem</v-btn>
                <v-btn text color="primary" @click="$refs.start_date_menu_2.save(start_date_2)">OK</v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="6">
            <v-menu ref="end_date_menu_2" v-model="end_date_menu_2" :close-on-content-click="false" :return-value.sync="end_date_2" transition="scale-transition" offset-y  min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field :rules="dateRules2" v-model="end_date_2" label="Befejező dátum" append-icon="event" readonly v-on="on" outlined>
                </v-text-field>
              </template>
              <v-date-picker v-model="end_date_2" no-title scrollable first-day-of-week=1>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="end_date_menu_2 = false">Mégsem</v-btn>
                <v-btn text color="primary" @click="$refs.end_date_menu_2.save(end_date_2)">OK</v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          </v-row>
          <v-row>
            <v-btn text color="red" @click="start_date_2 = '', end_date_2 = '' ">Töröl</v-btn>
          </v-row>
        </v-expansion-panel-content>    
      </v-expansion-panel>

        <v-expansion-panel>
        <v-divider></v-divider>
        <v-expansion-panel-header><b>Kategóriák</b></v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col
              cols="12"
            >
              <v-select
                outlined
                v-model="sel_cats"
                item-text="soap_course_type_code"
                item-value="course_type_key"
                :items="categories"
                label="Kategóriák"
                multiple
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-btn text color="red" @click="sel_cats = []">Töröl</v-btn>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

    </v-expansion-panels>
    </v-card>

     <v-spacer class="mt-2 mb-2"></v-spacer>
     <v-btn :disabled="!valid" text color="primary" @click="refreshAll(), beiratkozok_show = true, felvett_show = false, forgalom_show = false">Beiratkozók</v-btn>
     <v-btn :disabled="!valid" text color="primary" @click="getTanulok(), beiratkozok_show = false, felvett_show = true, forgalom_show = false">Felvett tanulók</v-btn>
     <v-btn text color="red" @click="today()">Mai nap</v-btn>
     <v-btn text color="green" @click="forgalom(), beiratkozok_show = false, felvett_show = false, forgalom_show = true">Forgalom</v-btn>
    </v-form>
   </v-container>
  </v-card>
  <br>

<v-card class="d-print-none" v-if="forgalom_show">
  <v-card-title>Forgalom</v-card-title>
  <v-container>
  <h2 class="text-center">({{start_date}} - {{end_date}}) <br> {{all_payments}}</h2>
  <br>
  <v-simple-table>
      <thead>
        <tr>
          <th class="text-left">
            Megnevezés
          </th>
          <th class="text-left">
            Összeg
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in forgalom_datas"
          :key="item.name"
        >
          <td>{{ item.name }}</td>
          <td>{{ item.value }}</td>
        </tr>
      </tbody>
  </v-simple-table>
  <br>

<v-card class="d-print-none" v-if="beiratkozok_show" outlined>
  <v-expansion-panels flat multiple>
    <v-expansion-panel>
      <v-expansion-panel-header class="title">
        Beiratkozók
      </v-expansion-panel-header>
      <v-expansion-panel-content>
          <v-data-table
          :headers="headers"
          :items="beiratkozok"
          :items-per-page="15"
          :loading="table_loader"
          loading-text="Betöltés..."
          sort-by="paid_date"
          :mobile-breakpoint='NaN'
          ></v-data-table>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel v-if="beiratkozok_egyeb != ''">
      <v-divider></v-divider>
      <v-expansion-panel-header class="title">
        Beiratkozók (egyéb)
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-data-table
          :headers="headers"
          :items="beiratkozok_egyeb"
          :items-per-page="15"
          :loading="table_loader"
          loading-text="Betöltés..."
          sort-by="paid_date"
          :mobile-breakpoint='NaN'
          ></v-data-table>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</v-card>
</v-container>
</v-card>

<v-card class="d-print-none" v-if="beiratkozok_show">
  <v-card-title>Időszak 1.</v-card-title>
  <v-container>
  <h2 class="text-center">({{start_date}} - {{end_date}})</h2>
  <h2 class="text-center" v-if="beiratkozok_show">Összesen: {{beiratkozok_sum}}</h2>
  <br>

<v-card class="d-print-none" v-if="beiratkozok_show" outlined>
  <v-expansion-panels flat multiple>
    <v-expansion-panel>
      <v-expansion-panel-header class="title">
        Beiratkozók
      </v-expansion-panel-header>
      <v-expansion-panel-content>
          <v-data-table
          :headers="headers"
          :items="beiratkozok"
          :items-per-page="15"
          :loading="table_loader"
          loading-text="Betöltés..."
          sort-by="paid_date"
          :mobile-breakpoint='NaN'
          ></v-data-table>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel v-if="beiratkozok_egyeb != ''">
      <v-divider></v-divider>
      <v-expansion-panel-header class="title">
        Beiratkozók (egyéb)
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-data-table
          :headers="headers"
          :items="beiratkozok_egyeb"
          :items-per-page="15"
          :loading="table_loader"
          loading-text="Betöltés..."
          sort-by="paid_date"
          :mobile-breakpoint='NaN'
          ></v-data-table>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</v-card>
</v-container>
</v-card>
<br>
<v-card class="d-print-none" v-if="beiratkozok_show && !table_loader && (beiratkozok_egyeb_2 || beiratkozok_2)">
  <v-card-title>Időszak 2.</v-card-title>
  <v-container>
  <h2 class="text-center">({{start_date_2}} - {{end_date_2}})</h2>
  <h2 class="text-center">Összesen: {{beiratkozok_sum_2}}</h2>
  <br>

<v-card class="d-print-none" outlined>
  <v-expansion-panels flat multiple>
    <v-expansion-panel>
      <v-expansion-panel-header class="title">
        Beiratkozók
      </v-expansion-panel-header>
      <v-expansion-panel-content>
          <v-data-table
          :headers="headers"
          :items="beiratkozok_2"
          :items-per-page="15"
          :loading="table_loader"
          loading-text="Betöltés..."
          sort-by="paid_date"
          :mobile-breakpoint='NaN'
          ></v-data-table>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-divider></v-divider>
      <v-expansion-panel-header class="title" v-if="beiratkozok_egyeb_2 != ''">
        Beiratkozók (egyéb)
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-data-table
          :headers="headers"
          :items="beiratkozok_egyeb_2"
          :items-per-page="15"
          :loading="table_loader"
          loading-text="Betöltés..."
          sort-by="paid_date"
          :mobile-breakpoint='NaN'
          ></v-data-table>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</v-card>
</v-container>
</v-card>

<p class="text-center" v-if="felvett_show"> Előjegyzés: {{elojegyzes}}</p>
<v-card class="d-print-none" v-if="felvett_show">
  <v-card-title>
    Felvett tanulók
  </v-card-title>
<v-data-table
 :headers="tanulok_headers"
 :items="tanulok"
 :items-per-page="15"
 :loading="table_loader"
 loading-text="Betöltés..."
 sort-by="paid_date"
 class="elevation-1"
 :mobile-breakpoint='NaN'
 ></v-data-table>
</v-card>
</v-container>
</div>
</template>

<script>
import {mapFields} from 'vuex-map-fields';
import { EventBus } from '../event-bus';

  export default {
    data () {
      return {
        beiratkozok: [],
        beiratkozok_egyeb: [],
        beiratkozok_sum: '',
        beiratkozok_2: [],
        beiratkozok_egyeb_2: [],
        beiratkozok_sum_2: '',
        tanulok: [],
        start_date_menu: false,
        start_date: new Date().toISOString().substr(0, 10),
        end_date: new Date().toISOString().substr(0, 10),
        end_date_menu: false,
        start_date_menu_2: false,
        start_date_2: '',
        end_date_2: '',
        end_date_menu_2: false,
        headers: [
          { text: 'Név', value: 'customer', width: 150 },
          { text: 'Szerződés', value: 'contract_name', width: 120 },
          { text: 'Szerződés típus', value: 'contract_type', width: 150 },
          { text: 'Szerződés dátum', value: 'contract_date', width: 150 },
          { text: 'Hely', value: 'place_name', width: 120 },
          { text: 'Összeg', value: 'amount', width: 100 },
          { text: 'Fizetési mód', value: 'payment_type', width: 120 },
          { text: 'Befizetés ideje', value: 'paid_date', width: 150 },
        ],
        tanulok_headers: [
          { text: 'Név', value: 'name', width: 150 },
          { text: 'Létszám', value: 'tanulok', width: 120 }
        ],
        table_loader: false,
        beiratkozok_show: true,
        felvett_show: false,
        elojegyzes: '',
        dateRules: [
          v => v >= this.start_date || 'A dátum nem lehet kisebb, mint a kezdő dátum!',
        ],
        dateRules2: [
          v => v >= this.start_date_2 || 'A dátum nem lehet kisebb, mint a kezdő dátum!',
        ],
        valid: true,
        categories: [],
        sel_cats: [],
        stat_dates: [],
        forgalom_show: false,
        all_payments: '',
        forgalom_datas: [],
      }
    },
    methods: {
      getBeiratkozok: async function() {
        this.beiratkozok = []
        this.beiratkozok_egyeb = []
        this.beiratkozok_2 = []
        this.beiratkozok_egyeb_2 = []
        this.table_loader = true
        await this.gettoken()
        this.$http.post('services/beiratkozok_221025', {
          token: this.token,
          start_date: this.start_date,
          end_date: this.end_date,
          iroda: this.user_place_id,
          categories: this.sel_cats,
          start_date_2: this.start_date_2,
          end_date_2: this.end_date_2,
        }).then((response) => {
          this.table_loader = false
          this.beiratkozok = response.data['beiratkozok']
          this.beiratkozok_egyeb = response.data['egyeb']
          this.beiratkozok_sum = response.data['sum']
          this.beiratkozok_2 = response.data['beiratkozok_2']
          this.beiratkozok_egyeb_2 = response.data['egyeb_2']
          this.beiratkozok_sum_2 = response.data['sum_2']
          console.log(response.data);
        }, (error) => {
          this.error_text = error.data
        })
      },
      forgalom: async function() {
        await this.gettoken()
        this.$http.post('services/forgalom', {
          token: this.token,
          start_date: this.start_date,
          end_date: this.end_date
        }).then((response) => {
          this.forgalom_datas = response.data;
          this.all_payments = response.data[4]['value'];
          console.log(response.data);
        }, (error) => {
          this.error_text = error.data
        })
      },
      getCategories: async function() {
        await this.gettoken()
        this.$http.post('services/nkh_categories', {
          token: this.token
        }).then((response) => {
          this.categories = response.data;
          console.log(response.data);
        }, (error) => {
          this.error_text = error.data
        })
      },
      getTanulok: async function() {
        this.tanulok = []
        this.table_loader = true
        await this.gettoken()
        this.$http.post('services/felvett_tanulok', {
          token: this.token,
          start_date: this.start_date,
          end_date: this.end_date
        }).then((response) => {
          this.table_loader = false
          this.tanulok = response.data['felvett_tanulok']
          this.elojegyzes = response.data['elojegyzes'][0]['count']
        }, (error) => {
          this.error_text = error.data
        })
      },
      refreshAll() {
        this.getBeiratkozok()
      },
      validate () {
        this.$refs.form.validate()
      },
      today () {
        this.start_date = new Date().toISOString().substr(0, 10)
        this.end_date = new Date().toISOString().substr(0, 10)
        this.stat_dates = [new Date().toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)]
        this.getBeiratkozok()
        this.getTanulok()
        this.forgalom()
      },
    },
    mounted() {
      this.getBeiratkozok()
      this.getCategories()
    },
    watch: {
      iroda() {
        this.getBeiratkozok()
      },
      start_date() {
        this.validate()
      },
      start_date_2() {
        this.validate()
      },
      stat_dates() {
        this.start_date = this.stat_dates[0];
        this.end_date = this.stat_dates[1];
      }
    },
    computed: {
    ...mapFields([
      'ids.fbuser',
      'ids.username',
      'ids.user_place_id',
      'ids.iroda',
      "ids.school_id",
      'functions.dates',
      'functions.expenses_select',
    ]),
    },
  }
</script>

<style lang="css">
</style>
