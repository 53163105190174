import Vuex from 'vuex'
import Vue from 'vue'
import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ids: {
      customerId: '',
      contractId: '',
      school_id: 1,
      billingo_id: '',
      invoice_id: null,
      invoice_number: null,
      preselected_school_id: '',
      preselected_invoice: '',
      preselected_invoice_source: '',
      invoice_source: 'all',
      fbuser: null,
      username: '',
      user_place_id: '',
      iroda: '',
      admin: '',
      mod_invoice_id: null
    },
    functions: {
      invoice_tab: 0,
      loader: false,
      invoice_preview: '',
      finalize: false,
      await: null,
      client_valid: true,
      invoice_valid: true,
      empty_items: false,
      preloader: false,
      create_message: '',
      create_error: '',
      create_dialog: false,
      dates: [new Date().toISOString().substr(0, 10)],
      expenses_select: 'elszam',
      refresh_store: false,
      panel: null,
      reset_param: false,
      dev_settings: '',
    },
  },

  getters: {
    getField
  },

  mutations: {
    updateField
  },

  actions: {

  }
})
