<template lang="html">
  <div class="component-wrapper">

    <v-card>
    <v-card-title>
      Számlák listája
      <v-spacer></v-spacer>
      <v-select
        v-model="invoice_source"
        :items="invoice_source_items"
        label="Bizonylat típus"
        item-value="value"
        item-text="text"
        outlined
        dense
        class="mt-6"
      ></v-select>
      <v-spacer></v-spacer>
      <v-select
        v-model="paid_status"
        :items="paid_items"
        label="Státusz"
        item-value="value"
        item-text="text"
        outlined
        dense
        class="mt-6"
      ></v-select>
      <v-spacer></v-spacer>
      <v-select
        v-model="payment_method_status"
        :items="payment_method_items"
        label="Fizetési mód"
        item-value="value"
        item-text="text"
        outlined
        dense
        class="mt-6"
      ></v-select>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="invoice_search"
        @keyup.enter="advancedSearch()"
        @click:clear="advancedSearch()"
        label="Keresés"
        outlined
        hide-details
        append-icon="search"
        clearable
      ></v-text-field>
    </v-card-title>
    <v-data-table
    :headers="headers"
    :items="invoice_list"
    :items-per-page="15"
    :search="search"
    sort-by="cration_date"
    sort-desc
    class="elevation-1"
    show-expand
  >
  <template v-slot:item.data-table-expand="{ item }">
  <v-menu>
  <template v-slot:activator="{ on }">
    <v-btn
      icon
      v-on="on"
    >
      <v-icon>mdi-dots-vertical</v-icon>
    </v-btn>
  </template>
  <v-list>
    <v-list-item v-if="!item.cedrus_id" @click="printInvoice(item.invoice_id)">
      <v-list-item-title>
        Nyomtatás
      </v-list-item-title>
    </v-list-item>
    <v-list-item v-if="!item.cedrus_id" @click="downloadInvoice(item.invoice_id, item.invoice_number)">
      <v-list-item-title>
        Letöltés
      </v-list-item-title>
    </v-list-item>
    <v-list-item v-if="!item.cedrus_id && item.status_id != 4 && item.is_cancel_invoice !=1 && item.invoice_type_id != 1" :disabled="item.invoice_type_id == 9" @click="invoice_set_id = item.invoice_id, invoice_set_number = item.invoice_number, invoice_set_name = item.client, storno_dialog = true">
      <v-list-item-title>
        Sztornó
      </v-list-item-title>
    </v-list-item>
    <v-list-item v-if="!item.cedrus_id && item.status_id != 4 && item.is_cancel_invoice !=1 && item.invoice_type_id != 1" :disabled="item.is_modification" @click="contractId = item.contract_id, mod_invoice_id = item.invoice_id, invoice_tab = 1" to="/">
      <v-list-item-title>
        Módosító számla
      </v-list-item-title>
    </v-list-item>
    <v-list-item v-if="!item.cedrus_id && item.invoice_type_id == 1 && item.status_id == 1 && !item.connected_invoice_no" @click="invoice_set_id = item.invoice_id, invoice_set_number = item.invoice_number, invoice_set_name = item.client, finalize_dialog = true">
      <v-list-item-title>
        Számla készítés
      </v-list-item-title>
    </v-list-item>
  <v-list-item v-if="!item.cedrus_id && (item.payment_method == 2 || item.payment_method == 17) && item.status_id == 2" @click="pay_date= new Date().toISOString().substr(0, 10), invoice_set_id = item.invoice_id, invoice_set_number = item.invoice_number, invoice_set_name = item.client, isproform = item.invoice_type_id, pay_dialog = true">
    <v-list-item-title>
      Kiegyenlítés
    </v-list-item-title>
  </v-list-item>
  <v-list-item v-if="item.cedrus_id" @click="openCedrusInvoice(item.cedrus_id)">
    <v-list-item-title>
      Megnyitás
    </v-list-item-title>
  </v-list-item>
  <v-list-item v-if="item.cedrus_id && item.status_id == 68 && item.invoice_type_id == 5" @click="createCedrusInvoice(item.cedrus_id)">
    <v-list-item-title>
      Számla készítés
    </v-list-item-title>
  </v-list-item>
  <v-list-item v-if="item.cedrus_id && item.status_id != 68 && item.status_id != 148 && item.status_id != 96 && item.status != 'Számla elkészült'" @click="cedrus_pay_date= new Date().toISOString().substr(0, 10), cedrus_invoice_set_id = item.cedrus_id, cedrus_invoice_set_number = item.invoice_number, cedrus_invoice_set_name = item.client, cedrus_pay_dialog = true">
    <v-list-item-title>
      Kiegyenlítés
    </v-list-item-title>
  </v-list-item>
  <v-list-item v-if="item.cedrus_id && item.status_id != 96" @click="cedrus_invoice_set_id = item.cedrus_id, cedrus_invoice_set_number = item.invoice_number, cedrus_invoice_set_name = item.client, cedrus_storno_dialog = true">
    <v-list-item-title>
      Sztornó
    </v-list-item-title>
  </v-list-item>
  <v-list-item v-if="!item.cedrus_id" @click="email_ivId = item.invoice_id, email_ivNumber = item.invoice_number, email_contractId = item.contract_id, getStudentDatas(), email_dialog = true">
    <v-list-item-title>
      E-mail
    </v-list-item-title>
  </v-list-item>
  </v-list>
</v-menu>
  </template>
  </v-data-table>
  </v-card>

  <v-snackbar
     v-model="snackbar"
   >
     {{ response }}
     {{ error }}
     <v-btn
        color="red"
        text
        @click="snackbar = false"
      >
        Bezár
      </v-btn>
   </v-snackbar>

   <v-dialog v-model="storno_dialog" persistent max-width="380">
      <v-card>
        <v-card-title class="headline">Sztornózás</v-card-title>
        <v-card-text>Biztosan sztornózza a bizonylatot? <br>
          ({{invoice_set_number}}, {{invoice_set_name}})</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="cancelInvoice(), storno_dialog = false">Igen</v-btn>
          <v-btn color="green darken-1" text @click="storno_dialog = false">Nem</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="finalize_dialog" persistent max-width="380">
       <v-card>
         <v-card-title class="headline">Számla kiállítása</v-card-title>
         <v-card-text>Biztosan kiállítja a számlát a díjbekérő alapján?<br>({{invoice_set_number}}, {{invoice_set_name}})</v-card-text>
         <v-card-actions>
           <v-spacer></v-spacer>
           <v-btn color="green darken-1" text @click="createFromProf(), finalize_dialog = false">Igen</v-btn>
           <v-btn color="green darken-1" text @click="finalize_dialog = false">Nem</v-btn>
         </v-card-actions>
       </v-card>
     </v-dialog>

    <v-dialog v-model="pay_dialog" persistent max-width="345">
       <v-card>
         <v-card-title class="headline">Fizetés rögzítése</v-card-title>
         <v-card-text>
           <v-row>
             <v-col>
               <b>Számlaszám:</b>
               {{invoice_set_number}}
            </v-col>
          </v-row>
          <v-row class="mb-3">
            <v-col>
           <b>Ügyfél:</b>
           {{invoice_set_name}}
            </v-col>
          </v-row>
         <v-date-picker v-model="pay_date" first-day-of-week="1"></v-date-picker>
         <v-row class="mb-3">
            <v-col cols="12">
              <v-combobox
                v-model="invoice_pay_name"
                :items="invoice_set_name"
                label="Befizető neve"
              ></v-combobox>
            </v-col>
            <v-col cols="12" v-if="isproform == 1">
              <v-checkbox
                v-model="autoorder"
                label="Automatikus számla kiállítás / tananyag rendelés"
              ></v-checkbox>
            </v-col>
         </v-row>
         </v-card-text>
         <v-card-actions>
           <v-spacer></v-spacer>
           <v-btn color="green" text @click="payInvoice(), pay_dialog = false" :disabled="!invoice_pay_name">Igen</v-btn>
           <v-btn color="red" text @click="pay_dialog = false, invoice_pay_name = ''">Nem</v-btn>
         </v-card-actions>
       </v-card>
     </v-dialog>

     <v-dialog v-model="cedrus_pay_dialog" persistent max-width="340">
        <v-card>
          <v-card-title class="headline">Fizetés rögzítése</v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <b>Számlaszám:</b>
                {{cedrus_invoice_set_number}}
             </v-col>
           </v-row>
           <v-row class="mb-3">
             <v-col>
            <b>Ügyfél:</b>
            {{cedrus_invoice_set_name}}
             </v-col>
           </v-row>
          <v-date-picker v-model="cedrus_pay_date" first-day-of-week="1"></v-date-picker>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="payCedrusInvoice(), cedrus_pay_dialog = false">Igen</v-btn>
            <v-btn color="green darken-1" text @click="cedrus_pay_dialog = false">Nem</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="cedrus_storno_dialog" persistent max-width="340">
         <v-card>
           <v-card-title class="headline">Sztornózás</v-card-title>
             <v-card-text>Biztosan sztornózza a bizonylatot? ({{cedrus_invoice_set_number}}, {{cedrus_invoice_set_name}})</v-card-text>
           <v-card-actions>
             <v-spacer></v-spacer>
             <v-btn color="green darken-1" text @click="cancelCedrusInvoice(), cedrus_storno_dialog = false">Igen</v-btn>
             <v-btn color="green darken-1" text @click="cedrus_storno_dialog = false">Nem</v-btn>
           </v-card-actions>
         </v-card>
       </v-dialog>

       <v-dialog v-model="email_dialog" persistent max-width="500">
          <v-card>
            <v-card-title class="headline">Bizonylat küldése</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="email_name"
                      outlined
                      label="Címzett"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="email_address"
                      outlined
                      label="E-mail cím"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="email_dialog = false" :disabled="button_loader">Mégsem</v-btn>
              <v-btn color="green darken-1" text @click="sendInvoice()" :disabled="button_loader" :loading="button_loader">Küld</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-snackbar
          v-model="order_loading"
          :timeout="0"
          bottom
          left
          color="blue lighten-5"
          text
          class="blue--text"
        >
          Számla kiállítás, tananyag megrendelés folyamatban...
        </v-snackbar>

  </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields'

  export default {
    data () {
      return {
        invoice_list: [],
        headers: [
          { text: 'Számlaszám', value: 'invoice_number', width: 180 },
          { text: 'Ügyfél', value: 'client', width: 180  },
          { text: 'Összeg', value: 'total_amount' },
          { text: 'Kiállítás ideje', value: 'cration_date', width: 130 },
          { text: 'Esedékesség', value: 'due_date' },
          { text: 'Fizetési mód', value: 'pm_name', width: 120 },
          { text: 'Bizonylat típus', value: 'invoice_type', width: 140 },
          { text: 'Státusz', value: 'status', width: 160 },
          { text: 'Befizető', value: 'who_paid', width: 160 },
          { text: 'Kiállító', value: 'created_by' },
        ],
        button_loader: false,
        response: '',
        error: '',
        snackbar: false,
        storno_dialog: false,
        cedrus_storno_dialog: false,
        invoice_set_number: '',
        invoice_set_name: '',
        invoice_set_id: null,
        menu: false,
        pay_date: new Date().toISOString().substr(0, 10),
        pay_dialog: false,
        finalize_dialog: false,
        search: '',
        invoice_search: '',
        cedrus_pay_date: new Date().toISOString().substr(0, 10),
        cedrus_invoice_set_number: '',
        cedrus_invoice_set_name: '',
        cedrus_invoice_set_id: null,
        cedrus_pay_dialog: false,
        invoice_source_items: [
          { text: 'Mind', value: 'all' },
          { text: 'Számlák', value: 'invoice' },
          { text: 'Díjbekérők', value: 'proform' },
        ],
        paid_items: [
          { text: 'Mind', value: 'all' },
          { text: 'Fizetett', value: 'paid' },
          { text: 'Nem fizetett', value: 'unpaid' },
        ],
        paid_status: 'all',
        payment_method_items: [
          { text: 'Mind', value: 'all' },
          { text: 'Készpénz', value: 'cash' },
          { text: 'Átutalás', value: 'transfer' },
          { text: 'Bankkártya', value: 'creditcard' },
          { text: 'Online bankkártya', value: 'online' },
          { text: 'Utalvány', value: 'voucher' },
        ],
        payment_method_status: 'all',
        email_dialog: false,
        email_ivId: '',
        email_ivNumber: '',
        email_contractId: '',
        email_name: '',
        email_address: '',
        invoice_pay_name: '',
        autoorder: false,
        isproform: '',
        order_loading: false
      }
    },
    methods: {
      getInvoices: async function() {
        this.preloader = true
        await this.gettoken()
        this.$http.post('services/invoice_list', {
          token: this.token,
          school_id: this.school_id,
        }).then((response) => {
          this.preloader = false
          this.invoice_list = response.data
        }, (error) => {
          this.error_text = error.data
        })
      },
      searchInvoices: async function() {
        this.search = ''
        this.preloader = true
        await this.gettoken()
        this.$http.post('services/invoice_search', {
          token: this.token,
          school_id: this.school_id,
          search: this.invoice_search,
        }).then((response) => {
          this.preloader = false
          this.invoice_list = response.data
        }, (error) => {
          this.error_text = error.data
        })
      },
      advancedSearch: async function() {
        this.search = ''
        this.preloader = true
        await this.gettoken()
        this.$http.post('services/advanced_search', {
          token: this.token,
          school_id: this.school_id,
          search: this.invoice_search,
          invoice_source: this.invoice_source,
          paid_status: this.paid_status,
          payment_method_status: this.payment_method_status
        }).then((response) => {
          this.preloader = false
          this.invoice_list = response.data
        }, (error) => {
          this.error_text = error.data
        })
      },
      getProforma: async function() {
        this.preloader = true
        await this.gettoken()
        this.$http.post('services/dbk_list', {
          token: this.token,
          school_id: this.school_id,
        }).then((response) => {
          this.preloader = false
          this.invoice_list = response.data
        }, (error) => {
          this.error_text = error.data
        })
      },
      downloadInvoice: async function(invoice_id, invoice_number) {
        this.preloader = true
        await this.gettoken()
        this.$http.post('billingo_services/download_invoice', {
          token: this.token,
          school_id: this.school_id,
          invoice_id: invoice_id
        }, {responseType: 'blob'}).then((response) => {
          this.preloader = false
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          var szamlaszam = invoice_number.replace("/", "-");
          link.setAttribute('download', szamlaszam+'.pdf');
          document.body.appendChild(link);
          link.click();
        }, (error) => {
          this.error_text = error.data
        })
      },
      printInvoice: async function(invoice_id) {
        this.preloader = true
        await this.gettoken()
        this.$http.post('billingo_services/download_invoice', {
          token: this.token,
          school_id: this.school_id,
          invoice_id: invoice_id,
        }, {responseType: 'blob'}).then((response) => {
          this.preloader = false
          //Create a Blob from the PDF Stream
          const file = new Blob(
          [response.data],
          {type: 'application/pdf'});
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          //Open the URL on new Window
          window.open(fileURL);
        }, (error) => {
          this.error_text = error.data
        })
      },
      cancelInvoice: async function() {
        this.preloader = true
        await this.gettoken()
        this.$http.post('billingo_services/cancel_invoice', {
          token: this.token,
          school_id: this.school_id,
          user: this.iroda,
          invoice_id: this.invoice_set_id
        }).then((response) => {
          this.preloader = false
          this.snackbar = true
          this.response = response.data['message']
          this.error = response.data['description']
          this.advancedSearch()
        }, (error) => {
          this.error_text = error.data
        })
      },
      createFromProf: async function() {
        this.preloader = true
        await this.gettoken()
        this.$http.post('billingo_services/finalize_invoice_230419', {
          token: this.token,
          school_id: this.school_id,
          user: this.iroda,
          invoice_id: this.invoice_set_id,
        }).then((response) => {
          this.preloader = false
          this.snackbar = true
          this.response = response.data['message']
          this.error = response.data['description']
          this.advancedSearch()
        }, (error) => {
          this.error_text = error.data
        })
      },
      payInvoice: async function() {
        this.preloader = true
        await this.gettoken()
        this.$http.post('billingo_services/pay_invoice_20221219', {
          token: this.token,
          school_id: this.school_id,
          invoice_id: this.invoice_set_id,
          pay_date: this.pay_date,
          user: this.iroda,
          invoice_pay_name: this.invoice_pay_name
        }).then((response) => {
          this.preloader = false
          this.snackbar = true
          this.response = response.data['message']
          this.error = response.data['description']
          this.invoice_pay_name = '';
          this.advancedSearch()
          if (this.autoorder == true) {
            this.autoOrder();
            this.autoorder = false;
          }
        }, (error) => {
          this.error_text = error.data
        })
      },
      autoOrder: async function() {
        var message = "Tananyag megrendelés folyamatban! Biztosan bezárja az oldalt?";
        window.onbeforeunload = function(event) {
            var e = e || window.event;
            if (e) {
                e.returnValue = message;
            }
            return message;
        };
        this.order_loading = true;
        await this.gettoken()
        this.$http.post('billingo_services/autoOrder', {
          token: this.token,
          school_id: this.school_id,
          invoice_id: this.invoice_set_id,
        }).then((response) => {
          window.onbeforeunload = null;
          this.order_loading = false;
          this.advancedSearch();
          console.log(response.data);
        }, (error) => {
          this.error_text = error.data
        })
      },
      payCedrusInvoice: async function() {
        this.preloader = true
        await this.gettoken()
        this.$http.post('billingo_services/pay_cedrus_invoice', {
          token: this.token,
          school_id: this.school_id,
          invoice_id: this.cedrus_invoice_set_id,
          pay_date: this.cedrus_pay_date,
        }).then((response) => {
          this.preloader = false
          this.snackbar = true
          this.response = response.data['message']
          this.error = response.data['description']
          this.advancedSearch()
        }, (error) => {
          this.error_text = error.data
        })
      },
      openCedrusInvoice(id) {
        if (this.dev_settings == 'dev') {
          window.open("https://apps.evonit.hu/cedrus-dev/#!invoice/cmd=view&id="+id, "_blank");
        } else if (this.dev_settings == 'prod') {
          window.open("https://apps.evonit.hu/cedrus/#!invoice/cmd=view&id="+id, "_blank");
        }
      },
      createCedrusInvoice: async function(cedrus_id) {
        this.preloader = true
        await this.gettoken()
        this.$http.post('billingo_services/finalize_proform', {
          token: this.token,
          user: this.iroda,
          cedrus_id: cedrus_id,
        }).then((response) => {
          this.preloader = false
          this.snackbar = true
          this.response = response.data['message']
          this.error = response.data['description']
          this.advancedSearch()
        }, (error) => {
          this.error_text = error.data
        })
      },
      cancelCedrusInvoice: async function() {
        this.preloader = true
        await this.gettoken()
        this.$http.post('billingo_services/cancel_cedrus_invoice', {
          token: this.token,
          user: this.iroda,
          cedrus_id: this.cedrus_invoice_set_id,
        }).then((response) => {
          this.preloader = false
          this.snackbar = true
          this.response = response.data['message']
          this.error = response.data['description']
          this.advancedSearch()
        }, (error) => {
          this.error_text = error.data
        })
      },
      getDatas: async function() {
        if (this.invoice_source == 'proform') {
          await this.getProforma()
          //this.search = this.preselected_invoice
        }
        else if (this.invoice_source == 'invoice') {
          await this.getInvoices()
          //this.search = this.preselected_invoice
        } else {
          this.getInvoices()
        }
      },
      getIvNumber() {
        this.invoice_search = this.preselected_invoice
      },
      getStudentDatas: async function() {
        this.email_name = '';
        this.email_address = '';
        await this.gettoken()
        this.$http.post('services/diakok_email_adatok', {
          token: this.token,
          contract_id: this.email_contractId
        }).then((response) => {
          this.email_name = response.data[0]['full_name'];
          this.email_address = response.data[0]['email1'];
          console.log(response.data);
        }, (error) => {
          this.error_text = error.data
        })
      },
      sendInvoice: async function() {
        this.button_loader = true
        await this.gettoken()
        this.$http.post('billingo_services/send_invoice', {
          token: this.token,
          school_id: this.school_id,
          invoice_id: this.email_ivId,
          full_iv_number: this.email_ivNumber,
          email: this.email_address,
          name: this.email_name
        }).then((response) => {
          this.button_loader = false
          console.log(response.data);
          if (response.data['success'] == true) {
            this.response = response.data['message'];
          } else {
            this.error = response.data['message'];
          }
          this.email_dialog = false
          this.snackbar = true
        }, (error) => {
          console.log(error.data);
          this.button_loader = false
        })
      },
    },
    mounted: async function() {
      await this.getIvNumber();
      this.advancedSearch();
      //this.getDatas()
      //this.search = this.preselected_invoice
    },
    watch: {
      invoice_id() {
        this.advancedSearch();
        //this.getInvoices()
      },
      school_id() {
        this.advancedSearch();
        //this.getDatas()
      },
      invoice_source() {
        this.advancedSearch();
      },
      paid_status() {
        this.advancedSearch();
      },
      payment_method_status() {
        this.advancedSearch();
      }
    },
    computed: {
    ...mapFields([
      'ids.customerId',
      'ids.contractId',
      'ids.school_id',
      'ids.preselected_school_id',
      'ids.iroda',
      'ids.billingo_id',
      'ids.invoice_id',
      'ids.mod_invoice_id',
      'ids.preselected_invoice',
      'ids.invoice_source',
      'functions.invoice_tab',
      'functions.loader',
      'functions.invoice_preview',
      'functions.finalize',
      'functions.await',
      'functions.client_valid',
      'functions.invoice_valid',
      'functions.preloader',
      'functions.dev_settings',
  ]),
  },
  }
</script>

<style lang="css">
</style>
