import Vue from 'vue'
import App from './App.vue'
import firebase from 'firebase/app'
import 'firebase/auth';
import vuetify from './plugins/vuetify';
import axios from 'axios'
import router from './router'
import store from './store'
import VueTheMask from 'vue-the-mask'
import VueJwtDecode from 'vue-jwt-decode'

Vue.use(VueTheMask)
Vue.config.productionTip = false
Vue.config.performance = true

const base = axios.create({
  baseURL: 'https://szamlazo.csigaver.hu/'
})

Vue.prototype.$http = base

var config = {
  apiKey: "AIzaSyDIbSkOk5sL72FWKY7x4xoWy71JoKUzdcA",
  authDomain: "szamlazo-ec79a.firebaseapp.com",
  databaseURL: "https://szamlazo-ec79a.firebaseio.com",
  projectId: "szamlazo-ec79a",
  storageBucket: "szamlazo-ec79a.appspot.com",
  messagingSenderId: "466654234749",
  appId: "1:466654234749:web:a2bfdee635c5cebf9d4914"
}

firebase.initializeApp(config)

if (store.state.ids.customerId) {
  store.state.functions.panel = 0
}

Vue.mixin({
  data () {
    return {
    }
  },
  computed: {
  customerid () {
    return store.state.ids.customerId
    }
  },
  methods: {
    gettoken: async function () {
      var user = firebase.auth().currentUser;
      if (user) {
      await firebase.auth().currentUser.getIdToken().then((idToken) => {
        this.token = idToken
        this.decoded_token = VueJwtDecode.decode(idToken)
        this.logout_time = new Date(this.decoded_token.auth_time * 1000 + (1000 * 60 * 60) * 10)
        this.now = new Date(Date.now())
      }).catch((error) => {
        alert(error)
      })
    /* if (this.logout_time < this.now) {
        firebase.auth().signOut().then(function() {
          console.log("Session time expired!");
        }).catch(function(error) {
          alert(error)
        });
      } */
     }
    },
  },
  mounted() {

  },
  watch: {
    customerid(value) {
      if (value) {
        store.state.functions.panel = 0
      }
    },
  }
})

let app = ''
firebase.auth().onAuthStateChanged(() => {
if (!app) {
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
}
})
