<template lang="html">
  <div class="component-wrapper">
    <v-form
    ref="invoice_form"
    v-model="invoice_valid"
    lazy-validation
    >
        <v-container>
          <v-card>
          <v-card-text>
          <v-row>
            <v-col cols="12" md="2">
              <v-select
                :value="def_invoice_type"
                @change="def_invoice_type = $event"
                :items="invoice_type"
                label="Bizonylat típus"
                item-value="value"
                return-object
                outlined
                flat
                solo
                :disabled="mod_invoice_id"
                item-disabled="disable"
              ></v-select>
            </v-col>
            <v-col cols="12" md="2">
              <v-select
                :value="block"
                @change="block = $event"
                :items="iv_blocks"
                label="Számlatömb"
                item-value="id"
                item-text="[attributes][name]"
                outlined
                flat
                solo
                :disabled="mod_invoice_id"
              ></v-select>
            </v-col>
            <v-col cols="12" md="2">
              <v-autocomplete
                :value="payment"
                @change="payment = $event"
                :items="payment_methods"
                item-text="[attributes][name]"
                item-value="id"
                label="Fizetési mód"
                outlined
                flat
                solo
                return-object
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="fulfillment_date"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="fulfillment_date"
                    hint="Teljesítés időpontja"
                    persistent-hint
                    append-icon="event"
                    readonly
                    outlined
                    flat
                    solo
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="fulfillment_date" no-title scrollable first-day-of-week="1">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">Mégsem</v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(fulfillment_date)">OK</v-btn>
                </v-date-picker>
              </v-menu>
          </v-col>
          <v-col cols="12" md="3"  v-if="payment['id'] == 2 || payment == 2 || payment['id'] == 17 || payment == 17">
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              :return-value.sync="due_date"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="due_date"
                  hint="Fizetési határidő"
                  persistent-hint
                  append-icon="event"
                  readonly
                  outlined
                  flat
                  solo
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="due_date" no-title scrollable first-day-of-week="1">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu2 = false">Mégsem</v-btn>
                <v-btn text color="primary" @click="$refs.menu2.save(due_date)">OK</v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" v-if="!mod_invoice_id">
            <v-radio-group v-model="electronic_invoice" row>
              <v-radio label="Hagyományos számla" value="0"></v-radio>
              <v-radio label="E-számla" value="1"></v-radio>
            </v-radio-group>
            </v-col>
          </v-row>
        </v-card-text>
        </v-card>
        <br>
        <div class="subtitle-1 black--text"><b>Számlasorok</b></div>
        <v-divider></v-divider><br>
        <v-card
          v-for="(item, index) in items" v-bind:key="index"
          outlined
          shaped
          class="mb-2"
        >
        <v-card-text>
          <v-row>
            <v-col cols="12" md="3">
              <span>Termék neve</span>
              <v-autocomplete
                v-model="item.product_uid"
                :items="product_list"
                item-text="[attributes][comment]"
                item-value="[id]"
                label="Termék választása"
                outlined
                flat
                solo
                return-object
                :rules="[rules.required]"
                @change="setValue(index, $event)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="2">
              <span>Mennyiség</span>
              <v-text-field
                v-model="item.qty"
                label="Mennyiség"
                outlined
                solo
                flat
                type="number"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <span>Mennyiségi egység</span>
              <v-text-field
                :value="item.unit"
                @change="item.unit = $event"
                label="Mennyiségi egység"
                outlined
                solo
                flat
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <span>Nettó egységár</span>
              <v-text-field
                :value="item.net_unit_price"
                disabled
                outlined
                flat
                solo
                :rules="[rules.required]"
                @change="item.net_unit_price = $event, item.brt_unit_price = Math.round(item.net_unit_price*(item.vat_pct+1))"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <span>Bruttó egységár</span>
              <v-text-field
                :value="item.brt_unit_price"
                flat
                solo
                outlined
                :rules="[rules.required]"
                @change="item.brt_unit_price = $event, item.net_unit_price = item.brt_unit_price/(item.vat_pct+1)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <span>ÁFA kulcs</span>
              <v-autocomplete
                :value="item.vat_pct"
                @change="item.vat_pct = $event"
                :items="vat_codes"
                item-text="[attributes][description]"
                item-value="[attributes][value]"
                label="ÁFA kulcs választása"
                outlined
                disabled
                flat
                solo
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="7">
              <v-text-field
                :value="item.item_comment"
                @change="item.item_comment = $event"
                label="Megjegyzés"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
            <v-btn @click="removeLicenseLine(index, item.line_id)" color="red" outlined class="mt-2">
              Számlasor törlés
            </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        </v-card>
        <v-row>
        <v-col>
          <v-btn rounded color="primary" dark @click="addItem()">Új számlasor</v-btn>
        </v-col>
        </v-row>
        <br>
        <v-card>
        <v-card-text>
          <div class="subtitle-1 black--text"><b>Megjegyzések</b></div>
          <v-divider></v-divider><br>
          <v-row>
          <v-col cols="12">
            <v-textarea
              :value="comment"
              @change="comment = $event"
              outlined
              flat
              solo
            ></v-textarea>
          </v-col>
          </v-row>
          <v-container>
          <v-row>
            <v-checkbox v-model="kozvszolg" label="A számla közvetített szolgáltatást tartalmaz." value="A számla közvetített szolgáltatást tartalmaz."></v-checkbox>
          </v-row>
          <v-row>
            <v-checkbox v-model="teljesitett" label="A számla pénzügyi teljesítést nem igényel." value="A számla pénzügyi teljesítést nem igényel."></v-checkbox>
          </v-row>
          </v-container>
          </v-card-text>
          </v-card>
          <br>
          <v-row>
            <v-btn rounded color="primary" dark @click="nextTab()">Tovább</v-btn>
          </v-row>
        </v-container>
    </v-form>
  </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import { EventBus } from '../event-bus';
var urlParams = new URLSearchParams(window.location.search);

  export default {
    data () {
      return {
        invoice_type: [
          { text: 'Piszkozat', value: 0 },
          { text: 'Díjbekérő', value: 1 },
          { text: 'Számla', value: 3 },
          { text: 'Módosító Számla', value: 9, disable: true },
        ],
        def_invoice_type: { text: 'Számla', value: 3 },
        payment_methods: [],
        payment: {id: 1, attributes: {id: 1, lang_code: "hu", name: "Készpénz", advance_paid: 1}},
        fulfillment_date: new Date().toISOString().substr(0, 10),
        due_date: new Date().toISOString().substr(0, 10),
        menu: false,
        menu2: false,
        items: [],
        product_list: [],
        vat_codes: [],
        kozvszolg: '',
        teljesitett: '',
        comment: '',
        rules: {
            required: value => !!value || 'Kötelező mező!',
          },
        iv_blocks: [],
        block: '',
        electronic_invoice: "0",
      }
    },
    methods: {
      getPaymentMethods: async function() {
        await this.gettoken()
        this.$http.post('billingo_services/getpaymetd', {
          token: this.token,
          school_id: this.school_id,
          def_invoice_type: this.def_invoice_type
        }).then((response) => {
          this.payment_methods = response.data
        }, (error) => {
          this.error_text = error.data
        })
      },
      getProducts: async function() {
        await this.gettoken()
        this.$http.post('billingo_services/getproducts', {
          token: this.token,
          school_id: this.school_id,
        }).then((response) => {
          this.product_list = response.data
        }, (error) => {
          this.error_text = error.data
        })
      },
      getVatCodes: async function() {
        await this.gettoken()
        this.$http.post('billingo_services/getvats', {
          token: this.token,
          school_id: this.school_id,
        }).then((response) => {
          this.vat_codes = response.data
        }, (error) => {
          this.error_text = error.data
        })
      },
      getIvBlocks: async function() {
        await this.gettoken()
        this.$http.post('billingo_services/getivblocks', {
          token: this.token,
          school_id: this.school_id,
        }).then((response) => {
          this.iv_blocks = response.data
          if (this.iroda == 'gyakorlat') {
            this.block = response.data[1]['id']
          } else {
            this.block = ''
          }
        }, (error) => {
          this.error_text = error.data
        })
      },
      getIvDetails: async function() {
        await this.gettoken()
        this.$http.post('billingo_services/get_document_v3', {
          token: this.token,
          invoice_id: this.mod_invoice_id,
          school_id: this.school_id
        }).then((response) => {
          this.payment = response.data[0]['attributes']['payment_method'];
          //this.due_date = response.data[0]['attributes']['due_date'];
          //this.fulfillment_date = response.data[0]['attributes']['fulfillment_date'];
          //this.comment = response.data[0]['attributes']['prefixed_invoice_no']+" számla módosítása";
          this.billingo_id = response.data[0]['attributes']['client_uid'];
          this.customerId = response.data[0]['attributes']['customerId'];
          console.log(response.data);
        }, (error) => {
          this.error_text = error.data
        })
      },
      createInvoice: async function() {
        this.invoice_id = null
        this.invoice_number = null
        this.loader = true
        await this.gettoken()
        this.$http.post('billingo_services/create_invoice', {
          token: this.token,
          school_id: this.school_id,
          user: this.iroda,
          billingo_id: this.billingo_id,
          contract_id: this.contractId,
          student_id: this.customerId,
          invoice_type: this.def_invoice_type['value'],
          invoice_type_name: this.def_invoice_type['text'],
          fulfillment_date: this.fulfillment_date,
          due_date: this.due_date,
          payment_method: this.payment['id'],
          payment_name: this.payment['attributes']['name'],
          items: this.items,
          comment1: this.kozvszolg,
          comment2: this.teljesitett,
          comment: this.comment,
          block_uid: this.block,
          electronic_invoice: this.electronic_invoice,
        }).then((response) => {
          this.loader = false
          this.finalize = false
          this.invoice_id = response.data['id']
          this.invoice_number = response.data['iv_number']
          this.create_message = response.data['message']
          this.create_error = response.data['description']
          this.create_dialog = true
        }, (error) => {
          this.error_text = error.data
        })
      },
      createModInvoice: async function() {
        this.invoice_id = null
        this.invoice_number = null
        this.loader = true
        await this.gettoken()
        this.$http.post('billingo_services/create_mod_invoice', {
          token: this.token,
          school_id: this.school_id,
          user: this.iroda,
          billingo_id: this.mod_invoice_id,
          contract_id: this.contractId,
          student_id: this.customerId,
          invoice_type: this.def_invoice_type['value'],
          invoice_type_name: this.def_invoice_type['text'],
          fulfillment_date: this.fulfillment_date,
          due_date: this.due_date,
          payment_method: this.payment['id'],
          payment_name: this.payment['attributes']['name'],
          items: this.items,
          comment1: this.kozvszolg,
          comment2: this.teljesitett,
          comment: this.comment,
          block_uid: this.block,
          electronic_invoice: this.electronic_invoice,
        }).then((response) => {
          this.loader = false
          this.finalize = false
          this.invoice_id = response.data['id']
          this.invoice_number = response.data['iv_number']
          this.create_message = response.data['message']
          this.create_error = response.data['description']
          this.create_dialog = true
        }, (error) => {
          this.error_text = error.data
        })
      },
      previewInvoice: async function() {
        this.loader = true
        this.finalize = false
        await this.gettoken()
        this.$http.post('billingo_services/preview_invoice_20210728', {
          token: this.token,
          school_id: this.school_id,
          billingo_id: this.billingo_id,
          contract_id: this.contractId,
          invoice_type: this.def_invoice_type,
          fulfillment_date: this.fulfillment_date,
          due_date: this.due_date,
          payment_method: this.payment,
          items: this.items,
          comment1: this.kozvszolg,
          comment2: this.teljesitett,
          comment: this.comment,
          electronic_invoice: this.electronic_invoice,
        }).then((response) => {
          this.loader = false
          this.invoice_preview = response.data
          this.await = null
        }, (error) => {
          this.error_text = error.data
        })
      },
      addItem: function () {
        this.items.push({ product_uid: '', qty: '1', net_unit_price: '',  brt_unit_price: '', unit: 'db', item_comment: '', vat_id: '', vat_pct: '', vat_desc: '', kozvszolg: ''});
      },
      removeLicenseLine (lineId) {
        this.items.splice(lineId, 1)
      },
      setValue: function(index, item) {
      this.$set(this.items, index, { description: item.attributes.name, product_uid: item.id, kozvszolg: item.attributes.fokonyv_arbev, net_unit_price: item.attributes.price, unit: item.attributes.unit, vat_id: item.attributes.vat_id, vat_desc: item.attributes.vat.description, vat_pct: item.attributes.vat.value, brt_unit_price: Math.round(item.attributes.price*(item.attributes.vat.value+1)), qty: 1 })
      },
      rounding(value){
      Math.round(value);
      },
      nextTab: async function() {
        await this.validate()
        if (this.invoice_valid) {
          this.invoice_tab = 2
          this.scrollToTop()
        } else {
          this.scrollToTop()
        }
      },
      payment_test(value) {
        alert(value)
      },
      validate () {
        this.$refs.invoice_form.validate()
      },
      reset () {
        this.$refs.invoice_form.reset()
      },
      resetValidation () {
        this.$refs.invoice_form.resetValidation()
      },
      scrollToTop() {
       window.scrollTo(0,0);
      },
      deleteParams() {
        urlParams.delete('customerId');
        urlParams.delete('contractId');
        urlParams.delete('invoice_number');
        urlParams.delete('school');
      },
      newInvoice: async function() {
       await this.deleteParams()
       EventBus.$emit('clearBillingoIds')
       this.panel = null,
       this.invoice_id = null,
       this.billingo_id = null,
       this.customerId = null,
       this.contractId = null,
       this.invoice_preview = null,
       this.comment = null,
       this.teljesitett = null,
       this.electronic_invoice = "0",
       this.invoice_tab = 0,
       this.items = [],
       this.mod_invoice_id = null,
       this.payment = {id: 1, attributes: {id: 1, lang_code: "hu", name: "Készpénz", advance_paid: 1}},
       this.fulfillment_date = new Date().toISOString().substr(0, 10),
       this.def_invoice_type = { text: 'Számla', value: 3 },
       this.resetValidation()
      },
    },
    mounted() {
      this.getPaymentMethods();
      this.getProducts()
      this.getVatCodes()
      this.addItem()
      this.getIvBlocks()
    },
    watch: {
      payment(value) {
        if (!this.mod_invoice_id) {
          if (value != 2) {
            this.due_date = this.fulfillment_date
          }
        }
      },
      def_invoice_type(value) {
          if (value['value'] == 1) {
            this.payment = {id: 2, attributes: {id: 2, lang_code: "hu", name: "Átutalás", advance_paid: 0}}
          }
          this.getPaymentMethods();
      },
      fulfillment_date() {
        if (!this.mod_invoice_id) {
            this.due_date = this.fulfillment_date
          }
      },
      school_id() {
        this.getPaymentMethods();
        this.getProducts()
        this.items = []
        this.invoice_id = null
        this.getIvBlocks()
      },
      username() {
        this.getIvBlocks()
      },
      await(value) {
          if (value == 'ready' && this.items) {
            if (this.client_valid && this.invoice_valid && !this.empty_items) {
              this.previewInvoice()
            }
          }
      },
      finalize(value) {
          if (value == true && this.mod_invoice_id == null) {
            this.createInvoice()
          } else if (value == true && this.mod_invoice_id != null) {
            this.createModInvoice()
          }
      },
      items(value) {
        if (Object.keys(value).length == 0) {
          this.empty_items = true
        } else {
          this.empty_items = false
        }
        var szolg;
        value.forEach(function(element){
          if (element['kozvszolg'] == 'kozvetitett') {
            szolg = 1
          }
        })
        if (szolg == 1) {
          this.kozvszolg = "A számla közvetített szolgáltatást tartalmaz."
        } else {
          this.kozvszolg = '';
        }
      },
      invoice_tab(value) {
        if (value !=1) {
          this.validate()
        }
      },
      mod_invoice_id(value) {
        if (value) {
          this.items = []
          this.invoice_id = null
          this.getIvDetails()
          this.def_invoice_type = { text: 'Módosító Számla', value: 9, disable: true }
        }
      }
    },
    created() {
      EventBus.$on('newInvoice', () => {
            this.newInvoice();
      })
    },
    computed: {
    ...mapFields([
      'ids.customerId',
      'ids.contractId',
      'ids.school_id',
      'ids.billingo_id',
      'ids.iroda',
      'ids.fbuser',
      'ids.username',
      'functions.invoice_tab',
      'functions.loader',
      'functions.invoice_preview',
      'functions.finalize',
      'ids.invoice_id',
      'ids.mod_invoice_id',
      'ids.invoice_number',
      'functions.await',
      'functions.client_valid',
      'functions.invoice_valid',
      'functions.empty_items',
      'functions.create_message',
      'functions.create_error',
      'functions.create_dialog',
      'functions.panel',
      'functions.reset_param',
  ]),
  },
  }
</script>

<style lang="css">
</style>
