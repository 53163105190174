<template lang="html">
<div>
  <v-card class="d-print-none">
    <v-card-title>
      <v-col cols="12" md="6">
      Gyakorlati tandíj igénylések
      </v-col>
      <v-col cols="12" md="5">
      <v-text-field
        v-model="search"
        clearable
        label="Keresés"
        single-line
        hide-details
      ></v-text-field>
      </v-col>
    </v-card-title>
    <v-container>
      <v-row>
        <v-col cols="12" md="6">
          <v-radio-group
            v-model="egyosszegu_select"
            row
          >
            <v-radio
              label="Átvett"
              value="paid"
            ></v-radio>
            <v-radio
              label="Folyamatban"
              value="nopaid"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" md="6">
            <v-btn
              v-if="admin"
              color="primary"
              dark
              @click="getTcInvoices()"
            >
              Oktatói Számlák
            </v-btn>
        </v-col>
      
    </v-row>
    <v-row>
        <v-col cols="12" md="2">
            <v-btn
              v-if="selected_payments != '' && egyosszegu_select != 'paid'"
              color="primary"
              dark
              @click="checkTeachers()"
            >
              Beküldés
            </v-btn>
        </v-col>
        <v-col cols="12" md="2">
            <v-btn
              v-if="selected_payments != ''"
              color="primary"
              dark
              @click="printRequests()"
            >
              Lista nyomtatás
              <v-icon
                right
                dark
              >
                print
              </v-icon>
            </v-btn>
        </v-col>
    </v-row>
  <v-data-table
   v-model="selected_payments"
   :headers="egyosszegu_headers"
   :items="payments"
   :items-per-page="15"
   :search="search"
   :loading="table_loader"
   loading-text="Betöltés..."
   sort-by="used"
   sort-desc
   :mobile-breakpoint='NaN'
   item-key="payment_id"
   show-select
   ></v-data-table>
   </v-container>
  <v-divider></v-divider>

        <v-card-actions v-if="selected_payments != '' && admin">
          <v-row>
          <v-col cols="12" md="4">
                  <v-menu
                    v-model="date_menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        class="mt-4"
                        v-model="date"
                        label="Kifizetés ideje"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        dense
                        :rules="[rules.required]"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                      @input="date_menu = false"
                      no-title
                      scrollable
                      :first-day-of-week="1"
                    ></v-date-picker>
                  </v-menu>
          </v-col>
          <v-col cols="12" md="6" class="mt-4">
          <v-btn
            color="primary"
            dark
            @click="payPayments()"
          >
            rögzít
            <v-icon
              right
              dark
            >
              save
            </v-icon>
          </v-btn>
          </v-col>
          <v-col cols="12" md="2" class="mt-4">
          <v-btn
            color="red"
            dark
            @click="del_payment_dialog = true"
          >
            töröl
            <v-icon
              right
              dark
            >
              delete
            </v-icon>
          </v-btn>
          </v-col>
          </v-row>
        </v-card-actions>
 </v-card>

    <v-dialog
      v-model="request_dialog"
      width="500"
      scrollable
      persistent
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Igénylés beküldése
        </v-card-title>

        <v-card-text>
          <v-form
            ref="request_form"
            v-model="valid"
          >
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="teacher_invoice"
                    label="Számla száma"
                    filled
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="teacher_invoice_amount"
                    label="Számla összege"
                    filled
                    v-mask="'#######'"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-menu
                    v-model="date_menu_2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="teacher_invoice_due_date"
                        label="Fizetési határidő"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        filled
                        :rules="[rules.required]"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="teacher_invoice_due_date"
                      @input="date_menu_2 = false"
                      no-title
                      scrollable
                      :first-day-of-week="1"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  <v-file-input
                    v-model="invoice_to_upload"
                    filled
                    small-chips
                    label="Számla feltöltése"
                    :rules="[rules.required]"
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="red"
            outlined
            @click="cancelRequest()"
          >
            mégsem
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            outlined
            @click="sendRequest()"
            :disabled="!valid"
          >
            küld
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="teacherIv_dialog"
      min-width="800"
      scrollable
      persistent
    >
      <v-card>
        <v-toolbar
          color="grey lighten-2"
        >
          <v-toolbar-title>Oktatói számlák</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              icon
              @click="teacherIv_dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
        <v-container>
        <v-col cols="12" md="5">
        <v-text-field
          v-model="search_tciv"
          clearable
          label="Keresés"
          single-line
          hide-details
        ></v-text-field>
        </v-col>
            <v-data-table
              v-model="selected_tcInvoides"
              :headers="teacherIv_headers"
              :items="teacher_invoices"
              :items-per-page="15"
              :search="search_tciv"
              sort-by="due_date"
              :mobile-breakpoint='NaN'
              item-key="request_id"
              show-select
            >
            <template v-slot:item.print="{ item }">
                <v-btn
                  depressed
                  color="primary"
                  :disabled="!item.teacher_iv_url"
                  @click="printTeacherInvoice(item.teacher_iv_url)"
                >
                  <v-icon dark>
                    print
                  </v-icon>
                </v-btn>
            </template>
            </v-data-table>
        </v-container>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions v-if="selected_tcInvoides != ''">
          <v-row>
          <v-col cols="12" md="4">
                  <v-menu
                    v-model="date_menu_3"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        class="mt-4"
                        v-model="date"
                        label="Kifizetés ideje"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        dense
                        :rules="[rules.required]"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                      @input="date_menu_3 = false"
                      no-title
                      scrollable
                      :first-day-of-week="1"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6" class="mt-4">
                  <v-btn
                    color="primary"
                    dark
                    @click="confirm_dialog = true"
                  >
                    rögzít
                    <v-icon
                      right
                      dark
                    >
                      save
                    </v-icon>
                  </v-btn>
                  </v-col>
          <v-col cols="12" md="2" class="mt-4">
          <v-btn
            color="red"
            dark
            @click="del_confirm_dialog = true"
          >
            töröl
            <v-icon
              right
              dark
            >
              delete
            </v-icon>
          </v-btn>
          </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="confirm_dialog"
      persistent
      max-width="400"
    >
      <v-card>
        <v-container>Biztosan fizetettre állítja a kiválasztott számlákat?</v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            outlined
            @click="confirm_dialog = false"
          >
            Nem
          </v-btn>
          <v-btn
            color="green"
            outlined
            @click="payInvoices()"
          >
            Igen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="del_confirm_dialog"
      persistent
      max-width="400"
    >
      <v-card>
        <v-container>Biztosan törli a kiválasztott számlákat?</v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            outlined
            @click="del_confirm_dialog = false"
          >
            Nem
          </v-btn>
          <v-btn
            color="green"
            outlined
            @click="delInvoices()"
          >
            Igen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="del_payment_dialog"
      persistent
      max-width="400"
    >
      <v-card>
        <v-container>Biztosan törli a kiválasztott igényléseket?</v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            outlined
            @click="del_payment_dialog = false"
          >
            Nem
          </v-btn>
          <v-btn
            color="green"
            outlined
            @click="delPayments()"
          >
            Igen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
    >
      {{ response }}

        <v-btn
          color="pink"
          text
          @click="snackbar = false"
        >
          bezár
        </v-btn>
    </v-snackbar>

    <v-snackbar
      v-model="error_snackbar"
      color="red accent-2"
    >
      {{ error_response }}

        <v-btn
          color="white"
          text
          @click="error_snackbar = false"
        >
          bezár
        </v-btn>
    </v-snackbar>

          <v-btn
          style="top: 120px;"
            elevation="0"
            fab
            fixed
            top
            right
            color="pink"
            dark
            @click="getPayments()"
          >
          <v-icon>refresh</v-icon>
          </v-btn>

</div>
</template>

<script>
import { mapFields } from "vuex-map-fields";

export default {
  data() {
    return {
      egyosszegu_select: "nopaid",
      payments: [],
      selected_payments: [],
      egyosszegu_headers: [
        { text: "Tanuló", value: "student_name", width: 250 },
        { text: "Összeg", value: "amount", width: 100 },
        { text: "Megnevezés", value: "item_name", width: 150 },
        { text: "Igénylés dátuma", value: "used", width: 150 },
        { text: "Beküldve", value: "send_time", width: 150 },
        { text: "Számlaszám", value: "invoice_number", width: 200 },
        { text: "Oktató", value: "teacher", width: 150 },
        { text: "Átvétel", value: "atvetel", width: 150 },
        { text: "Igénylő", value: "office", width: 120 },
      ],
      teacherIv_headers: [
        { text: "Nyomtatás", value: "print" },
        { text: "Oktató", value: "teacher_name", width: 250 },
        { text: "Összeg", value: "teacher_iv_amount", width: 100 },
        { text: "Számlaszám", value: "teacher_invoice", width: 200 },
        { text: "Fizetési határidő", value: "due_date", width: 150 },
        { text: "Igénylés dátuma", value: "send_time", width: 150 },
        { text: "Átvétel", value: "atvetel", width: 150 },
        { text: "Beküldő", value: "request_office", width: 120 },
      ],
      search: "",
      date: new Date().toISOString().substr(0, 10),
      teacher_invoice_due_date: "",
      date_menu: false,
      date_menu_2: false,
      date_menu_3: false,
      response: "",
      snackbar: false,
      request_dialog: false,
      table_loader: false,
      teacher_invoice: '',
      teacher_invoice_amount: '',
      invoice_to_upload: null,
      uploading: false,
      error_response: '',
      error_snackbar: false,
      rules: {
        required: value => !!value || 'Kötelező mező!',
      },
      valid: true,
      teacher_invoices: [],
      teacherIv_dialog: false,
      selected_tcInvoides: [],
      search_tciv: '',
      confirm_dialog: false,
      del_confirm_dialog: false,
      del_payment_dialog: false,
    };
  },
  methods: {
    getPayments: async function () {
      this.payments = [];
      this.table_loader = true;
      await this.gettoken();
      this.$http
        .post("services/egyosszegu_230221", {
          token: this.token,
          search: this.search,
          iroda: this.iroda,
          egyosszegu_select: this.egyosszegu_select
        })
        .then(
          (response) => {
            this.table_loader = false;
            this.payments = response.data;
          },
          (error) => {
            this.error_text = error.data;
          }
        );
    },
    getTcInvoices: async function () {
      this.teacher_invoices = [];
      await this.gettoken();
      this.$http
        .post("services/egyosszegu_szamlak", {
          token: this.token,
          egyosszegu_select: this.egyosszegu_select
        })
        .then(
          (response) => {
            console.log(response.data);
            this.teacher_invoices = response.data;
            this.teacherIv_dialog = true;
          },
          (error) => {
            this.error_text = error.data;
          }
        );
    },
    printTeacherInvoice: async function(invoice_url) {
        this.preloader = true
        await this.gettoken()
        this.$http.post('services/tc_iv_download', {
          token: this.token,
          invoice_url: invoice_url,
        }, {responseType: 'blob'}).then((response) => {
          this.preloader = false
          //Create a Blob from the PDF Stream
          const file = new Blob(
          [response.data],
          {type: 'application/pdf'});
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          //Open the URL on new Window
          window.open(fileURL);
        }, (error) => {
          this.error_text = error.data
        })
      },
    paidPayments: async function () {
      this.payments = [];
      this.table_loader = true;
      await this.gettoken();
      this.$http
        .post("services/egyosszegu_paid_230217", {
          token: this.token,
          search: this.search,
          iroda: this.iroda,
        })
        .then(
          (response) => {
            this.table_loader = false;
            this.payments = response.data;
          },
          (error) => {
            this.error_text = error.data;
          }
        );
    },
    payPayments: async function () {
      await this.gettoken();
      this.$http
        .post("services/egyosszegu_atvet", {
          token: this.token,
          date: this.date,
          items: this.selected_payments,
        })
        .then(
          (response) => {
            this.getPayments();
            this.selected_payments = [];
            this.response = response.data;
            this.snackbar = true;
          },
          (error) => {
            this.error_text = error.data;
          }
        );
    },
    delPayments: async function () {
      this.del_payment_dialog = false;
      await this.gettoken();
      this.$http
        .post("services/egyosszegu_torol", {
          token: this.token,
          date: this.date,
          items: this.selected_payments,
        })
        .then(
          (response) => {
            this.getPayments();
            this.selected_payments = [];
            this.response = response.data;
            this.snackbar = true;
          },
          (error) => {
            this.error_text = error.data;
          }
        );
    },
    payInvoices: async function () {
      this.confirm_dialog = false
      await this.gettoken();
      this.$http
        .post("services/egyosszegu_szamla_atvet", {
          token: this.token,
          date: this.date,
          items: this.selected_tcInvoides,
        })
        .then(
          (response) => {
            this.getTcInvoices();
            this.getPayments();
            this.selected_tcInvoides = [];
            this.response = response.data;
            this.snackbar = true;
          },
          (error) => {
            this.error_text = error.data;
          }
        );
    },
    delInvoices: async function () {
      this.del_confirm_dialog = false
      await this.gettoken();
      this.$http
        .post("services/egyosszegu_szamla_torol", {
          token: this.token,
          date: this.date,
          items: this.selected_tcInvoides,
        })
        .then(
          (response) => {
            this.getTcInvoices();
            this.getPayments();
            this.selected_tcInvoides = [];
            this.response = response.data;
            this.snackbar = true;
          },
          (error) => {
            this.error_text = error.data;
          }
        );
    },
    checkTeachers() {
      let teachers = [];
      this.selected_payments.forEach((payment) => {
        teachers.push(payment["teacher"]);
      });
      const count_teachers = new Set(teachers);
      if (count_teachers.size == 1) {
        this.request_dialog = true;
      } else {
        this.error_response = "Egyszerre csak egy oktatóhoz tartozó igénylések adhatók le!";
        this.error_snackbar = true;
      }
    },
    printRequests: async function(datas) {
        await this.gettoken()
        this.$http.post('services/egyosszegu_print_list', {
          token: this.token,
          selected_payments: this.selected_payments,
        }, {responseType: 'blob'}).then((response) => {
          this.preloader = false
          const file = new Blob(
          [response.data],
          {type: 'application/pdf'});
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }, (error) => {
          this.error_text = error.data
        })
      },
      sendRequest: async function() {
        this.uploading = true
        // objektummá alakítjuk, mert a funkció több fájl feltöltésére lett írva alapvetően
        this.invoice_to_upload = [this.invoice_to_upload];

        let formData = new FormData();

        for( var i = 0; i < this.invoice_to_upload.length; i++ ){
          let file = this.invoice_to_upload[i];
          formData.append('files[' + i + ']', file);
        }

        formData.append('token', this.token);
        formData.append('teacher_name', this.selected_payments[0]['teacher']);
        formData.append('selected_payments', JSON.stringify(this.selected_payments));
        formData.append('teacher_invoice', this.teacher_invoice);
        formData.append('teacher_invoice_amount', this.teacher_invoice_amount);
        formData.append('teacher_invoice_due_date', this.teacher_invoice_due_date);
        formData.append('username', this.username);
        
        await this.gettoken()
        this.$http.post('services/egyosszegu_kuld_230519',
          formData,
					{
						headers: {
								'Content-Type': 'multipart/form-data'
						}
					}).then((response) => {
            this.uploading = false
            console.log(response.data);
            if (response.data['result'] == 'error') {
              this.error_response = response.data['message'];
              this.error_snackbar = true;
            } else {
              this.response = response.data['message'];
              this.snackbar = true;
              this.request_dialog = false;
              this.teacher_invoice = "";
              this.teacher_invoice_amount = "";
              this.teacher_invoice_due_date = "";
              this.invoice_to_upload = null;
              this.resetValidation ();
              this.getPayments();
            }
        }, (error) => {
          console.log(error.data);
        })
        
      },
      cancelRequest() {
        this.teacher_invoice = "";
        this.teacher_invoice_amount = "";
        this.teacher_invoice_due_date = "";
        this.invoice_to_upload = null;
        this.request_dialog = false;
        this.resetValidation ();
      },
      resetValidation () {
        this.$refs.request_form.resetValidation()
      },
  },
  mounted() {
    this.getPayments();
  },
  watch: {
    egyosszegu_select() {
      this.selected_payments = [];
      this.getPayments();
    },
  },
  computed: {
    ...mapFields([
      "ids.fbuser",
      "ids.username",
      "ids.user_place_id",
      "ids.iroda",
      "ids.school_id",
      "ids.admin",
      "functions.dates",
      "functions.expenses_select",
    ]),
  },
};
</script>

<style lang="css">
</style>
